import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class ApiScopesService {

      static getApiScopes() {
        return axios
          .get(`${baseApiUrl}${Url.apiScopes.getApiScopes}`)
          .then((response) => response);
      }

      static addApiScope(payload) {
        return axios
          .post(`${baseApiUrl}${Url.apiScopes.addApiScope}`,payload)
          .then((response) => response);
      }

      static updateApiScope(payload) {
        return axios
          .post(`${baseApiUrl}${Url.apiScopes.updateApiScope}`, payload)
          .then((response) => response);
      }

      static deleteApiScope(scopId) {
        return axios
          .get(`${baseApiUrl}${Url.apiScopes.deleteApiScope}/${scopId}`)
          .then((response) => response);
      }
}