import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Button,
  TextInput,
  Checkbox,
} from "../../../shared/ui-components";


const AddEditScopes = ({
  isAddForm,
  onAddSubmit,
  onEditSubmit,
  onCancel,
  selectedScope
}) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    
  });


  const getInitialFormValues = () => {

    if(selectedScope)
    {
        return {
            id: selectedScope.id,
            name: selectedScope.name,
            displayName: selectedScope.displayName,
            description: selectedScope.description,
            enabled: selectedScope.enabled,
            required: selectedScope.required,
            emphasize: selectedScope.emphasize,
            showInDiscoveryDocument: selectedScope.showInDiscoveryDocument,
            showInAudClaim: selectedScope.showInAudClaim
        }
    }

    return {
            name: "",
            displayName: "",
            description: "",
            enabled: true,
            required: false,
            emphasize: false,
            showInDiscoveryDocument: true,
            showInAudClaim: false
    }
  }

  return (
    <Formik
      initialValues={getInitialFormValues()}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {

        if (isAddForm) {
          await onAddSubmit(values);
          return;
        }
        await onEditSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {

        return (
          <Form
            className=""
            style={{ width: "100%", height: "100%", userSelect: "none" }}
          >
            <div
              className=""
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="">
                <h2 class="tab-title mb-32">
                  {isAddForm ? "Create Api Scope" : "Edit Api Scope"}
                </h2>
              </div>

              <div style={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
                <div className="row">
                  <div className="col-6 pl-20">
                  <Field
                      component={TextInput}
                      name="name"
                      label="Name"
                    />

                     <Field
                      component={TextInput}
                      name="displayName"
                      label="Display Name"
                    />

                    <Field
                      component={TextInput}
                      name="description"
                      label="Description"
                    />

                    <Field
                      component={Checkbox}
                      name="enabled"
                      label="Enabled"
                    />

                    <Field
                      component={Checkbox}
                      name="required"
                      label="Required"
                    />

                    <Field
                      component={Checkbox}
                      name="emphasize"
                      label="Emphasize"
                    />

                    <Field
                      component={Checkbox}
                      name="showInDiscoveryDocument"
                      label="Show In Discovery Document"
                    />

                    <Field
                      component={Checkbox}
                      name="showInAudClaim"
                      label="Show In AUD Claim"
                    />

                    
                    <div className="content-align-right">
                      <Button
                        type="submit"
                        text={isAddForm ? "Add" : "Update"}
                      />
                      <Button
                        type="button"
                        styleType="secondary ml-10"
                        text="Cancel"
                        onClick={onCancel}
                      />
                    </div>
                  </div>

                  <div className="col-6" />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditScopes;
