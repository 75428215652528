/* eslint-disable import/no-anonymous-default-export */
const UserComponent = {
  Validities: [
    { text: "Expires", value: "Expires" },
    { text: "Always valid", value: "Always valid" },
  ],

  Permissions: [
    { text: "Company Administrator", value: "Company Administrator" },
    { text: "Instance Administrator", value: "Instance Administrator" },
    { text: "Web User", value: "Web User" },
    { text: "Survey App User", value: "Survey App User" },
    { text: "Developer", value: "Developer" },
  ],

  ExternalShareEnabled: [
    { text: "User can share externally", value: "true" },
    { text: "User cannot share externally", value: "false" }
  ],

  TwoFactorEnabledOptions: [
    { text: "Enabled", value: "true" },
    { text: "Not enabled", value: "false" }
  ]
};

const ClientComponent = {
  GrantTypes: [
    { text: "Client Credentials", value: "client_credentials" },
    { text: "Authorization Code", value: "authorization_code" },
  ],
  Scopes: [
    { text: "frmsc_fast_safe_api", value: "frmsc_fast_safe_api" },
    { text: "frmsc_full_safe_api", value: "frmsc_full_safe_api" },
    { text: "frmsc_fri_api", value: "frmsc_fri_api" },
  ],
  RefreshTokenExpirationTypes: [
    { text: "Sliding", value: "0" },
    { text: "Absolute", value: "1" },
  ]
}

export   {UserComponent, ClientComponent} ;
