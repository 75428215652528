import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import "react-datepicker/dist/react-datepicker.css";

import ErrorMessage from "./ErrorMessage";
import moment from "moment";

const DatePicker = ({ removeMargin = false, ...props }) => {
  return (
    <React.Fragment>
      {!removeMargin ? (
        <div className="form-field">
          <DatePickerControl {...props} />
        </div>
      ) : (
        <DatePickerControl {...props} />
      )}
    </React.Fragment>
  );
};

export default DatePicker;

const DatePickerControl = ({
  label,
  value,
  form,
  field,
  children,
  placeHolder,
  dateFormat = 'dd/MM/yyyy',
  defaultSelectedDate,
  tabIndex,
  hourUid,
  shouldRenderTimePickerControl,
  isTimePickerFocused,
  ...props
}) => {

  const datePickerRef = useRef();
  const [currentDateText, setCurrentDateText] = useState(null);

  useEffect(() => {

    if(isTimePickerFocused)
    {
      closeCalender();
      beforeMaskedValueChange();
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isTimePickerFocused])


    const onDateChange = (selectedDate) => {
    if (form && field) {
      form.setFieldValue(field.name, selectedDate);
    }
    if(props.onChange)
    {
      props.onChange(selectedDate);
    }

    if(selectedDate && shouldRenderTimePickerControl && hourUid )
    {
      let hourElement = document.getElementsByClassName(hourUid);
      if(hourElement[0])
      {
        hourElement[0].focus();
        closeCalender();
      }
    }
       
  };

  const parseDateInputMask = (format) => {
     return format? format.split("").map(x => {if(x !== '/'){x = '1'} return x}).join(""): '11/11/1111'
  }

  const dateInputMask = parseDateInputMask(dateFormat);

  const onDatePickerFocus = (e) => {
    if(defaultSelectedDate && !form.values[field.name])
    {
      onDateChange(defaultSelectedDate);
    }
  }

  // const onDatePickerBlur = (e) => {
  //   closeCalender();
  // }

  const closeCalender = () => {
    datePickerRef.current.setOpen(false);
  }

  let beforeMaskedValueChange = () => {

    if(!currentDateText) return;

    const dateFormatParts = dateFormat.split("/");
    const dateParts = currentDateText.split("/");
    let parsedDate = new Date();

    let isValidDate = dateFormatParts.join("").length  === dateParts.join("").split("_").join("").length

    if(isValidDate)
    {
      parsedDate = getParsedDate(currentDateText);
      onDateChange(parsedDate);
      setCurrentDateText(currentDateText)
      
    }
    else
    {
      
      let formatedDateParts = dateParts.map((x,i) => {

        x = formatDateParts(x, dateFormatParts[i]);

        if(i !== (dateParts.length -1))
        {
          x += "/"
        }

        return x;
      });

      formatedDateParts = formatedDateParts.join("");
      setCurrentDateText(formatedDateParts);
      
      
      parsedDate = getParsedDate(formatedDateParts);
      
      onDateChange(parsedDate);
    }
    datePickerRef.current.setPreSelection(parsedDate);

  }

  const getParsedDate = (dateString) => {

    let parsedDate =  moment(new Date())
    const dateFormatParts = dateFormat.split("/");

    dateString.split("/").forEach((x, i) => {

      const isDayPart = dateFormatParts[i].includes("d") || dateFormatParts[i].includes("D");
    const isMonthPart = dateFormatParts[i].includes("m") || dateFormatParts[i].includes("M");

    if(isDayPart)
    {
      parsedDate = parsedDate.set('date', parseInt(x))
    }
    else if(isMonthPart)
    {
      parsedDate = parsedDate.set('month', parseInt(x) -1)
    }
    else
    {
      parsedDate = parsedDate.set('year', parseInt(x))
    }



    })

    parsedDate = parsedDate.toDate();
    parsedDate.setHours(form.values[field.name].getHours())
    parsedDate.setMinutes(form.values[field.name].getMinutes())

    return parsedDate;
  } 

  const formatDateParts = (datePart, dateFormatPart) =>  {

    datePart = datePart.split("_").join("");

    if(datePart.length === dateFormatPart.length) return datePart;

    const isDayPart = dateFormatPart.includes("d") || dateFormatPart.includes("D");
    const isMonthPart = dateFormatPart.includes("m") || dateFormatPart.includes("M");

    if(isDayPart || isMonthPart)
    {

      if(parseInt(datePart) < 9 && parseInt(datePart) > 0)
      {
        datePart = 0 + datePart
        return datePart;
      }

      return "01";

    }
    else
    {
      return "2000";
    }
  }

  const onRawChange = (value) => {

    if(!value) return;

    setCurrentDateText(value);
    const dateParts = value.split("/").join("").split("_")[0]?.length;
    const dateFormatParts = dateFormat.split("/").join("").length;

    if(dateParts === dateFormatParts)
    {
      onDateChange(getParsedDate(value));
    }

  }


  return (
    <React.Fragment>
      <label> {label} </label>
      <div className="date-field">
        <ReactDatePicker
        ref={datePickerRef}
        enableTabLoop={false}
        onCalendarOpen={onDatePickerFocus}
         dateFormat={dateFormat}
          placeholderText={placeHolder? placeHolder:" "}
          className="input-control custom-react-datepicker"
          selected={form.values[field.name]}
          customInput={<MaskedInput mask={dateInputMask}  value={currentDateText}/>}
          onChange={(val) => onDateChange(val)}
          onChangeRaw={(e) => onRawChange(e.target.value)}
          tabIndex={tabIndex}
          preventOpenOnFocus={true}
          onClickOutside={beforeMaskedValueChange}
        />
        <ErrorMessage form={form} field={field} />
      </div>
      {children}
    </React.Fragment>
  );
};
