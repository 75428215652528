import React from "react";

const  RangeSlider = (props) => {

  const onSliderChange = (event) =>  {

    const { form, field, onChange } = props;

    form.setFieldValue(field.name, event.target.value);
    
    if(onChange)
    {
      onChange(event.target);
    }
  }

  
  const getRangeLabelMargin = (value) => {
    return Number(((value - min) * 100) / (max - min)) +  '%'
  }

    const { label, min, max, step, children, form, field, rangeLabelFormatter } = props;
    const { ...restFieldProps } = field;
    let rangeValue = form.values[field.name];

    return (
      <div className="form-field">
        {label && <label> {label} </label>}
        <div className="range" data-value={rangeValue}>
          <div className="label" style={{ "left": getRangeLabelMargin(rangeValue) }}>
            <div  className="value">{rangeLabelFormatter? rangeLabelFormatter(rangeValue): rangeValue}</div>
          </div>
          <div className="input-box">
            <input
              type="range"
              min={min}
              max={max}
              step={step}
              onChange={(changeEvent) => {
                onSliderChange(changeEvent);

              }}
              {...restFieldProps}
            />
          </div>
        </div>
        {children}
      </div>
    );
}

RangeSlider.defaultProps = {
  label: "Range slider",
  min: 1,
  max: 100.0,
  step: 0.1,
  // min: 0,
  // max: 100,
};

export default RangeSlider;
