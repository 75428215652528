import React, { Component } from 'react'
import { MdOutlineDashboard, MdPrivateConnectivity, MdWeb, MdApi } from 'react-icons/md'
import { FaUsers, FaBuilding, FaCube, FaBoxOpen } from "react-icons/fa"

import { RouteConstants } from '../../../shared'
import { FrmscIcons, SideNavigationPanel, PageHeader } from '../../../shared/ui-components'
import HomeRoutes from '../homeRoutes'

import UserDefaultImage from '../../../assets/images/user-image.svg'
const FrmscLogo = require('../../../assets/images/frmscLogo.png').default

class HomeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideBarOptionsData: this.getSideBarOptionsData(),
      initialRoutes: [
        {
          routeName: 'Dashboard',
          routePath: '/',
          routeDisplayName: 'Dashboard',
        },
      ],
      isFetchingData: false,
      isSidebarExpanded: true,
    }
  }

  getSideBarOptionsData = () => {
    const { NavigationRoutes } = RouteConstants

    return NavigationRoutes.map(({ title, iconName, path }) => {
      const fontSize = '1.4rem';
      const Icon = ({ fontSize, iconName }) => (
        <div>
          {iconName === 'dashboard' && <MdOutlineDashboard fontSize={fontSize} />}
          {iconName === 'companies' && <FaBuilding fontSize={fontSize} />}
          {iconName === 'instances' && <FaCube fontSize={fontSize} />}
          {iconName === 'users' && <FaUsers fontSize={fontSize} />}
          {iconName === 'connected' && <MdPrivateConnectivity fontSize={fontSize} />}
          {iconName === 'packages' && <FaBoxOpen fontSize={fontSize} />}
          {iconName === 'clients' && <MdWeb fontSize={fontSize} />}
          {iconName === 'api' && <MdApi fontSize={fontSize} />}
          </div>)

      return {
        title,
        icon: (
          // <FrmscIcons
          //   name={iconName}
          //   set="light"
          //   primaryColor="white"
          //   size="medium"
          // />
          <Icon fontSize={fontSize} iconName={iconName} primaryColor="white" />
        ),
        onSideNavOptionClick: this.redirect.bind(this),
        path,
      }
    })
  }

  redirect = (title, path) => {
    const { history } = this.props
    history.push(path)
  }

  // navigateToUserAccountScreen = () => {
  //   const { history } = this.props;

  //   const { RedirectPaths } = RouteConstants;
  //   history.push(RedirectPaths.UserProfile);
  // };

  onSideBarToggle = (isSidebarExpanded) => {
    this.setState({ isSidebarExpanded })
  }

  render() {
    const { initialRoutes, isSidebarExpanded } = this.state
    const { BreadcrumbRoutes } = RouteConstants

    return (
      <React.Fragment>
            <SideNavigationPanel
          isExpanded={true}
          SideNavOptions={this.state.sideBarOptionsData}
          DefaultSelectedOptionIndex={0}
          SideNavTitle={'FRMSc'}
          SideNavLogo={FrmscLogo}
          onSideBarToggle={this.onSideBarToggle.bind(this)}
        />
        <div className={`content-wrapper ${isSidebarExpanded ? '' : 'active'}`}>
          <PageHeader
            Title={'Admin'}
            UserName={'Andrew Huston'}
            ProfileLinkText={'View Profile'}
            History={this.props.history}
            Routes={BreadcrumbRoutes}
            InitialRoutes={initialRoutes}
            ProfilePagePath={""}
            // OnProfileLinkTextClick={() => this.navigateToUserAccountScreen()}
            ProfilePictureImg={UserDefaultImage}
          />

          <HomeRoutes {...this.props} />
        </div>
      </React.Fragment>
    )
  }
}

export default HomeComponent
