import React from 'react'

const SmallCard = ({ as, cardContent, cardHeading, isActive, onClick }) => {
  
  const CardElement = () => {
    return React.createElement(as, {
      className: isActive? 'horizontal-item  active': 'horizontal-item',
      children: <CardContent />,
      onClick
    })
  }

  const CardContent = () => (
    <span className="horizontal-name">
      {cardHeading}
      <br />
      {cardContent}
    </span>
  )

  return <CardElement></CardElement>
}

SmallCard.defaultProps = {
  as: 'div',
  isActive: false
}

export default SmallCard
