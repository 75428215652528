import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import { UsersService, InstanceService, AccountService, CompanyService, StorageService } from "../../../services";
import { FormatFrmscDateTime } from "../../../shared/Utility";
import { UserComponent } from "../../../shared/constants/componentConstants";
import { RenderIf, SearchBox, ButtonGroup, Spinner , FlatList, TextInput, Dropdown, Title, Grid, DatePicker, Button, Checkbox, IconButton, ConfimrationDialog } from "../../../shared/ui-components";
import { parseDropdownData } from "../../../shared/Utility";

const Users = () => {
  const [selectedUser, setSelectedUser] = useState({});
  const [searchResultListData, setSearchResultListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [hasMoreDataToFetch, setHasMoreDataToFetch] = useState(true);
  const [isCenterSpinnerSpinning, setIsCenterSpinnerSpinning] = useState(true);
  const [isSearchListSpinnerSpinning, setIsSearchListSpinnerSpinning] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [state, setState] = useState({
    isEditSelected: false,
    isBookmarkSelected: false,
    isChatSelected: false,
    isAddSelected: false,
    isDeleteSelected:false
  });

  const [UserEditDropDowns, setUserEditDropDowns] = useState({
    companies: [],
    instances: [],
  });

  const [selectedUserAdditionalDetails, setSelectedUserAdditionalDetails] =
    useState({
      userPermissions: [],
      userLogins: [],
    });

  const [searchedListRecordsIndex, setSearchedListRecordsIndex] = useState({});
  const searchFilterLocalStorageKey = 'UserSearchFilters';
  
  const specialSortByKeys = {
    name: 'name',
    companyName: 'companyName'
  }
  
  const specialSortByParamValues = {
    name: 'usr.name',
    companyName: 'comp.name'
  }
  const [searchFilterParams, setSearchFilterParams] = useState({
      sortByParams: specialSortByKeys.name,
      filterByParams: "",
      sortBy: [
          { displayName: 'Username', key: 'username', value: false },
          { displayName: 'Name', key: specialSortByKeys.name, value: true },
          { displayName: 'Company name', key: specialSortByKeys.companyName, value: false },
      ],
      filterBy: [
        { displayName: 'Enabled', key: 'isEnabled', value: false },
        { displayName: 'Admin', key: 'isAdmin', value: false },
        { displayName: '2 factor', key: 'twoFactorEnabled', value: false }
      ],
      sortDirection: 'Desc'
  })

  useEffect(() => {
    const searchFilterInitialState = getSearchFiltersInitialState();
    searchUsers({page, pageSize, searchTerm, sortBy: searchFilterInitialState.sortByParams, filterBy: searchFilterInitialState.filterByParams, sortDirection: searchFilterInitialState.sortDirection});
    getDropdownsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function searchUsers({page, pageSize, searchTerm, sortBy, filterBy, sortDirection},) {

    let searchResultList = await UsersService.searchUsers(
      {
        page,
        pageSize,
        searchTerm,
        sortBy: getModifiedSortByParams(sortBy),
        filterBy,
        sortDirection
      }
    );
    if (searchResultList.data?.length) {
      searchResultList.data = searchResultList.data.map((userData) => {
        const name = userData.name ? userData.name : userData.email;
        userData.dateCreated = userData.dateCreated
          ? FormatFrmscDateTime(userData.dateCreated)
          : "";

        userData.userExpiry = new Date(userData.userExpiry);

        return { ...userData, name };
      });
      let firstRecord = searchResultList.data[0];
      getSelectedUserDetails(firstRecord.id);
      firstRecord.selected = true;
      updateUsersList(searchResultList.data);
      setSelectedUser(firstRecord);
    }
    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setPage(page + 1);
    setIsCenterSpinnerSpinning(false);
  }

  const getModifiedSortByParams = (sortyByKey) => {
    if(specialSortByKeys[sortyByKey]) return specialSortByParamValues[sortyByKey];

    return sortyByKey;
  }

  const getSearchFiltersInitialState = () => {

    const searchFilterState = StorageService.Get(searchFilterLocalStorageKey);

    if (searchFilterState) {
      setSearchFilterParams(searchFilterState);
      return searchFilterState;
    }

    StorageService.Set(searchFilterLocalStorageKey, searchFilterParams);
    return searchFilterParams;
  }

  const onRowSelection = (selectedRowData, index) => {
    let allRows = searchResultListData.map((data, instanceIndex) => {
      data.selected = instanceIndex === index;
      return data;
    });

    updateUsersList(allRows);
    setSelectedUser(selectedRowData);

    if (state.isAddSelected)
      setState({ ...state, isAddSelected: false, isEditSelected: true });

    getSelectedUserDetails(selectedRowData.id);
  };

  const searchData = async (searchTerm, selectionUserIndex, usersList) => {
    setSelectedUser("");
    setState({ isEditSelected: false });
    setIsCenterSpinnerSpinning(true);
    let searchResultList = await UsersService.searchUsers(
      { page: 1,
        pageSize,
        searchTerm,
        sortBy: getModifiedSortByParams(searchFilterParams.sortByParams),
        filterBy: searchFilterParams.filterByParams,
        sortDirection: searchFilterParams.sortDirection
      }
    );
    searchResultList.data = searchResultList.data.map((userData) => {
      const name = userData.name ? userData.name : userData.email;
      userData.dateCreated = userData.dateCreated
        ? FormatFrmscDateTime(userData.dateCreated)
        : "";
      userData.userExpiry = new Date(userData.userExpiry);
      return { ...userData, name };
    });
    let selectedRecord = null;
    if (usersList?.length) {
        searchResultList.data = searchResultList.data.filter(x => !usersList.find(y => y.id === x.id));
        searchResultList.data = [...usersList, ...searchResultList.data];
        selectedRecord = searchResultList.data[selectionUserIndex];
    }
    else {
        selectionUserIndex = searchResultList.data[selectionUserIndex] ? selectionUserIndex : 0;
        searchResultList.data[selectionUserIndex].selected = true;
        selectedRecord = searchResultList.data[selectionUserIndex];
        setSelectedUser(selectedRecord);
    }
    setIsCenterSpinnerSpinning(false)
    updateUsersList(searchResultList.data);

    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
      if (searchResultList.data?.length >= pageSize) {
          setPage(page + 1);
      }
    setIsCenterSpinnerSpinning(false);
    setSelectedUserAdditionalDetails({
      userPermissions: [],
      userLogins: [],
    });
  };

  const loadMore = async () => {
    setIsSearchListSpinnerSpinning(true);
    const searchPageNumber = page === 1 ? 2 : page;
    let searchResultList = await UsersService.searchUsers(
      { page: searchPageNumber,
        pageSize,
        searchTerm,
        sortBy: getModifiedSortByParams(searchFilterParams.sortByParams),
        filterBy: searchFilterParams.filterByParams,
        sortDirection: searchFilterParams.sortDirection
    }
    );

    searchResultList.data = searchResultList.data.map((userData) => {
      const name = userData.name ? userData.name : userData.email;
      userData.dateCreated = userData.dateCreated
        ? FormatFrmscDateTime(userData.dateCreated)
        : "";
      userData.userExpiry = new Date(userData.userExpiry);
      return { ...userData, name };
    });
    updateUsersList([...searchResultListData, ...searchResultList.data]);
    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setPage(searchPageNumber + 1);
    setIsSearchListSpinnerSpinning(false);
  };

  const setSelectedIcon = (selectedIcon) => {
    setState({ [selectedIcon]: true });
  };

  const selectIconButton = (iconName) => {
    setSelectedIcon(iconName);
  };

  const addUser = () => {
    setSelectedIcon("isAddSelected");
    setSelectedUser({});
  };

  const getDropdownsData = () => {
    Promise.all([
      InstanceService.getAllInstances(),
      CompanyService.getAllCompanies(),
    ]).then((resp) => {
      let instances = parseDropdownData(resp[0].data, "name", "id");
      let companies = parseDropdownData(resp[1].data, "name", "id");

      setUserEditDropDowns({
        ...UserEditDropDowns,
        instances,
        companies,
      });
    });
  };

  const handleSubmit = async (formData) => {
    setSearchTerm("");
    var error = false;
    parseFormValues(formData);


    if (state.isAddSelected) {
      await AccountService.inviteUser(formData).then(
        (data) => { },
        (err) => {

          console.log(err);
          error = true;
          alert(err.errorMessage);
        }
      );
    } else {
      UsersService.updateUser(formData).then((data) => {
        if (!formData.isEnabled) {
          revokeUser();
        }
      },
        (err) => {

          console.log(err);
          error = true;
          alert(err.errorMessage);
        });

    }



    if (!error) {
      setState({ ...state, isEditSelected: false, isAddSelected: false });
      if (state.isAddSelected) {
        searchUsers({
          page: 1, 
          pageSize: 10, 
          searchTerm: "",
          sortBy: searchFilterParams.sortByParams,
          filterBy: searchFilterParams.filterByParams,
          sortDirection: searchFilterParams.sortDirection
      });
        return;
      }

      formData.company = UserEditDropDowns.companies.find(
        (company) => company.value === formData.companyId
      ).text;

      const { instanceId, ...updateUser } = formData;
      let userToUpdate = searchResultListData.find((x) => x.id === formData.id);
      Object.assign(userToUpdate, updateUser);
      updateUsersList(searchResultListData);
      setSelectedUser(updateUser);
    }
  };

  const parseFormValues = (formData) => {
    formData.companyId = parseInt(formData.companyId);
    formData.dateCreated = null;
    formData.isExternalShareEnabled = JSON.parse(formData.isExternalShareEnabled);
    formData.twoFactorEnabled = JSON.parse(formData.twoFactorEnabled);

    if(formData.passwordValidity === "Always valid"){
      formData.passwordExpiry = 0;
    }

    if(formData.userValidity === "Always valid"){
      formData.userExpiry = null;
    }
  };

  const getSelectedUserDetails = async (userId) => {
    const { data } = await UsersService.getUserDetails(userId);

    setSelectedUserAdditionalDetails({
      userPermissions: data.userPermissions,
      userLogins: data.userLogins,
    });
  };

  const revokeUser = () => {
    AccountService.revokeUserSession(selectedUser.id).then((resp) => {
      alert("Revoked successfully!");
    });
  };

  const deleteUser = () =>{
    setSelectedIcon("isDeleteSelected")
  }

  const onDeleteUserConfirm = () => {
    const { removedRecordIndex, updatedUsersList, removedUserId } = removeSelectedUserFromList();

    setIsCenterSpinnerSpinning(true);
    onToggleDeleteUserConfirmationPopup(false);
    UsersService.deleteUser(removedUserId).then((response) => {
        setIsCenterSpinnerSpinning(false);
        searchData(searchTerm, removedRecordIndex, updatedUsersList)
    }, (err) => {
        setIsCenterSpinnerSpinning(false);
    })
  }

  const onToggleDeleteUserConfirmationPopup = (isButtonClicked) => {
    setState({ ...state, isDeleteSelected: isButtonClicked });
  }

  const updateUsersList = (listData) => {
    setSearchResultListData(listData)
    updateListRecordsIndex(listData);
  }

  const updateListRecordsIndex = (listData) => {
    let recordsIndex = {};
    listData.forEach((x, i) => {
        recordsIndex[x.id] = i;
    });
    setSearchedListRecordsIndex(recordsIndex);
  }

  const removeSelectedUserFromList = () => {

    const removedUserId = selectedUser.id;
    const filteredList = searchResultListData.filter(x => removedUserId !== x.id);
    let removedRecordIndex = searchedListRecordsIndex[removedUserId];

    if (filteredList?.length) {
        removedRecordIndex = filteredList[removedRecordIndex] ? removedRecordIndex : removedRecordIndex - 1;
        filteredList[removedRecordIndex].selected = true;
        setSelectedUser(filteredList[removedRecordIndex]);
    }

    updateUsersList(filteredList)

    return { removedRecordIndex, updatedUsersList: filteredList, removedUserId };
  }

  const onSearchFilterChange = (updatedFilterParams) => {
    updatedFilterParams.sortByParams = updatedFilterParams.sortByParams ? updatedFilterParams.sortByParams : specialSortByKeys.name
    setSearchFilterParams(updatedFilterParams);

    searchUsers({
      page: 1, pageSize, searchTerm, sortBy: updatedFilterParams.sortByParams, filterBy: updatedFilterParams.filterByParams,
      sortDirection: updatedFilterParams.sortDirection
    })
  }

  return (
    <section className="content-box">
      <div className="search-section pt-33">

        <div className='search-box-container'>

        <SearchBox
            placeholder="Search for users"
            onSearch={searchData}
            shouldUseFilter={true}
            searchFilterParams={searchFilterParams}
            onSearchFilterChange={(updatedFilterParams) => onSearchFilterChange(updatedFilterParams)}
        />

        </div>

        <div className='search-list-container'>

          <div className='search-result-container shadow'>

            <FlatList
              data={searchResultListData}
              leftContentKey="name"
              rightContentKey="dateCreated"
              onRowClick={onRowSelection}
              hasMoreDataToFetch={hasMoreDataToFetch}
              loadMore={loadMore}
              isFetchingMoreData={isSearchListSpinnerSpinning}
              isSearchingData={isCenterSpinnerSpinning}
            />

          </div>
        </div>
      </div>

      <div className="details-section pt-33">
        <div className='action-buttons-section'>

        <div className='left-action-buttons-group'>
                        <div className='mr-5 d-flex '>
                            <IconButton
                                tooltipTitle=""
                                tooltipPostion="top"
                                isActive={state.isAddSelected}
                                onClick={() => addUser()}
                                name="add"
                                as="button"
                                label="Create user"
                            />
                        </div>
                    </div>

          <ButtonGroup className="action-buttons-group">

            <div className="mr-5">
              <IconButton
                tooltipTitle="Chat"
                tooltipPostion="top"
                isActive={state.isChatSelected}
                onClick={() => setSelectedIcon('isChatSelected')}
                name="chat"
                as="button"
              />
            </div>
            <div className="mr-5">
              <IconButton
                tooltipTitle="Edit"
                tooltipPostion="top"
                isActive={state.isEditSelected}
                onClick={() => selectIconButton("isEditSelected")}
                name="edit"
                as="button"
                disabled={state.isAddSelected}
              />
            </div>
            <div className="mr-5">
              <IconButton
                tooltipTitle="Bookmark"
                tooltipPostion="top"
                isActive={state.isBookmarkSelected}
                onClick={() => setSelectedIcon('isBookmarkSelected')}
                name="bookmark"
                as="button"
              />
            </div>
            <div className="mr-5">
              <IconButton
                  disabled={!selectedUser?.id || state.isEditSelected}
                  tooltipTitle="Delete"
                  tooltipPostion="top"
                  isActive={state.isDeleteSelected}
                  onClick={() => deleteUser()}
                  name="delete"
                  as="button"
              />
              </div>
          </ButtonGroup>


        </div>

        <Spinner
          IsCenterSpinnerSpinning={
            isCenterSpinnerSpinning
          }
        />


<div className="details-section-scroll-under-action-buttons">
        <div className='details-section-container '>
          <div className='details-container shadow'>

            <div className="row pl-25 pr-25">
              <div className="col-12 col-xl-12 col-xxl-12">

                <RenderIf
                  shouldRender={!state.isEditSelected && !state.isAddSelected && selectedUser}
                >
                  <UserInfo
                    selectedUser={selectedUser}
                    onRevokeUser={() => revokeUser()}
                  />
                </RenderIf>

                <RenderIf
                  shouldRender={(state.isEditSelected || state.isAddSelected && selectedUser)}
                >
                  <UserForm
                    dropDownsData={UserEditDropDowns}
                    selectedUser={selectedUser}
                    state={state}
                    handleSubmit={handleSubmit}
                  />
                </RenderIf>

                <RenderIf
                  shouldRender={!state.isEditSelected && !state.isAddSelected && selectedUser}
                >
                  <Title content="Permissions" className="mt-30" dark />
                  <Grid
                    keysToIgnore={["instanceId"]}
                    data={selectedUserAdditionalDetails.userPermissions}
                    headerKeys={["Instance Name", "Access Level"]}
                  />

                  <Title content="User Logins" className="mt-30" dark />
                  <Grid
                    keysToIgnore={["userId", "id"]}
                    headerKeys={["Login Time", "Location", "Ip Address"]}
                    data={selectedUserAdditionalDetails.userLogins.map(
                      ({ loginTime, location, clientIpAddress }) => {
                        return { loginTime: FormatFrmscDateTime(loginTime), location, clientIpAddress };
                      }
                    )}
                  />
                </RenderIf>

              </div>
            </div>
          </div>
        </div>
        </div>

      </div>
      <ConfimrationDialog
          isModalOpen={state.isDeleteSelected}
          onToggleDialog={(isDialogOpen) => onToggleDeleteUserConfirmationPopup(isDialogOpen)}
          confimrationMessage={`Do you want to delete ${selectedUser.name}`}
          okBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          title="Delete user"

          cancelBtnStyleType="white"
          cancelBtnClick={() => onToggleDeleteUserConfirmationPopup(false)}
          okBtnClick={() => onDeleteUserConfirm()}
      />
    </section>
  );
};

export default Users;

const FormField = ({ label, value }) => (
  <div className="form-field">
    <label htmlFor="field">{label}</label>
    {value}
  </div>
);

const UserInfo = ({ selectedUser, onRevokeUser }) => (
  <div className="form-inline">
    <FormField label="Name:" value={selectedUser.name} />
    <FormField label="Email:" value={selectedUser.email} />
    <FormField label="Company:" value={selectedUser.company} />
    <FormField
      label="Password Expiry (days):"
      value={selectedUser.passwordExpiry}
    />
    <FormField label="Created Date:" value={selectedUser.dateCreated} />
    <FormField label="Created By:" value={selectedUser.createdByUser} />

    <div className="content-align-right mt-30">
      <Button text="Revoke User" onClick={() => onRevokeUser()} />
    </div>
  </div>
);

const UserForm = ({ dropDownsData, selectedUser, state, handleSubmit }) => {
  const validationSchema = Yup.object({
    instanceId: Yup.string().required("Please select an instance."),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required."),
  });

  return (
    <Formik
      initialValues={{
        ...selectedUser,
        name: selectedUser.name || "",
        email: selectedUser.email || "",
        companyId: selectedUser.companyId || "",
        instanceId: selectedUser.instanceId || "",
        isEnabled: selectedUser.isEnabled || true,
        changePasswordNextLogin: selectedUser.changePasswordNextLogin || false,
        isAdmin: selectedUser.isAdmin || false,
        userExpiry: selectedUser.userExpiry,
        passwordExpiry: selectedUser.passwordExpiry || 0,
        twoFactorEnabled: selectedUser.twoFactorEnabled || false,
        permission: selectedUser.permission || '',
        isExternalShareEnabled: selectedUser.isExternalShareEnabled || false,
        userValidity: selectedUser.userValidity || "Always valid",
        passwordValidity: selectedUser.passwordValidity || "Always valid"

      }}
      enableReinitialize={true}
      validationSchema={!state.isEditSelected && validationSchema}
      onSubmit={(values) => {
        //console.log("form data ", values);
        handleSubmit(values);
      }}
    >
       {({ values }) => <Form>
        <div className="form-inline">
          <RenderIf shouldRender={!state.isEditSelected}>
            <Field
              component={Dropdown}
              name="instanceId"
              label="Instance:"
              defaultOption="Select instance"
              options={dropDownsData.instances}
            />
          </RenderIf>

          <Field component={TextInput} name="name" label="Name:" />

          <Field
            component={TextInput}
            type="email"
            name="email"
            label="Email:"
            disabled={state.isEditSelected}
          />

          <Field
            component={Dropdown}
            name="companyId"
            label="Company:"
            defaultOption="Select company"
            options={dropDownsData.companies}
          />

          <Field
            component={Dropdown}
            name="userValidity"
            label="User Validity:"
            defaultOption="Select user validity"
            options={UserComponent.Validities}
          />

          <Field
            component={Dropdown}
            name="passwordValidity"
            label="Password Validity:"
            defaultOption="Select Password Validity"
            options={UserComponent.Validities}
          />

          <RenderIf shouldRender={values.passwordValidity === "Expires"}>
          <Field
            type="number"
            name="passwordExpiry"
            label="Password Expiry:"
            component={TextInput}
          />
          </RenderIf>

          <RenderIf shouldRender={values.userValidity === "Expires"}>
          <Field
            name="userExpiry"
            label="User Expiry:"
            component={DatePicker}
          />
          </RenderIf>

          <Field
              component={Dropdown}
              name="isExternalShareEnabled"
              label="External Share Enabled:"
              options={UserComponent.ExternalShareEnabled}
          />

          <RenderIf shouldRender={state.isAddSelected}>
            <Field
              component={Dropdown}
              name="permission"
              label="Permission:"
              defaultOption="Select permission"
              options={UserComponent.Permissions}
            />
          </RenderIf>

          <Field
            component={Dropdown}
            name="twoFactorEnabled"
            label="Two Factor Enabled:"
            defaultOption="Select enabled or not"
            options={UserComponent.TwoFactorEnabledOptions}
          />
        </div>
        <div className="content-align-right mt-30">
          <Button text={state.isEditSelected ? "Update" : "Invite"} />
        </div>
      </Form>}
    </Formik>
  );
};
