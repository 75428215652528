import React from 'react'

const RadioSwitchButtonLabelPosition = {
  Right: 'right',
  Left: 'left',
}

const RadioSwitchButton = ({
  label,
  position,
  data,
  ...props
}) => {
  return (
    <React.Fragment>
      <div className="form-field">
        <div className="inline-form">
          {label && position === RadioSwitchButtonLabelPosition.Left && (
            <p className="inline-small-title">{label}</p>
          )}
          <ul className="inline-check">
            {data && data.length && data.map( (x, index) => (
              <li key={index}>
                <RadioButtonControl {...props} index={index} text={x?.text} value={x?.value} />
              </li>
            ))}
          </ul>
          {label && position === RadioSwitchButtonLabelPosition.Right && (
            <p className="inline-small-title">{label}</p>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

RadioSwitchButton.defaultProps = {
    position: "left",
  };

export default RadioSwitchButton

const RadioButtonControl = ({ value, text, index, field, ...restProps }) => {
  const id = `${value}${index}${text}`
  return (
    <>
      <input {...field} id={id} type="radio" checked={field?.value === value} value={value} autoComplete="off" />
      <label htmlFor={id}>{text}</label>
    </>
  )
}
