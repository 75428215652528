import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { MdClose } from "react-icons/md";

import { RenderIf, TextInput, Dropdown, Checkbox, SearchBox, ButtonGroup, Button, Card, FlatList, IconButton, Spinner, Grid, Title, Dialog, ConfimrationDialog } from '../../../shared/ui-components'
import { UserComponent } from "../../../shared/constants/componentConstants";
import "../styles/instances.css";

import { InstanceService, NotificationService, UsersInstanceService, StorageService } from "../../../services";
import { GenerateUUID, FormatFrmscDateTime, parseDropdownData } from "../../../shared/Utility";

const Instances = (props) => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [searchResultListData, setSearchResultListData] = useState("");
  const [searchedListRecordsIndex, setSearchedListRecordsIndex] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [hasMoreDataToFetch, setHasMoreDataToFetch] = useState(true);
  const [isCenterSpinnerSpinning, setIsCenterSpinnerSpinning] = useState(true);
  const [isBottomSpinnerSpinning, setIsBottomSpinnerSpinning] = useState(false);
  const [isRightSpinnerSpinning, setIsRightSpinnerSpinning] = useState(false);
  const [isAddUserDialogSpinnerSpinning, setIsAddUserDialogSpinnerSpinning] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [state, setState] = useState({
    isEditSelected: false,
    isBookmarkSelected: false,
    isChatSelected: false,
    isAddSelected: false,

    isAddUserDialogOpened: false,
    isDeleteInstanceDialogOpened: false
  });
  const [instanceEditDropDowns, setInstanceEditDropDowns] = useState({
    companies: [],
    instanceTypes: [],
    packages: [],
    servers: []
  });
  const [selectedInstanceAssociatedUsers, setSelectedInstanceAssociatedUsers] = useState([]);
  const [addUserValidationMessage, setAddUserValidationMessage] = useState("");

  const [isClientAddedSuccessfully, setIsClientAddedSuccessfully] = useState(false);
  const [addedClientDetails, setAddedClientDetails] = useState("");
  const searchFilterLocalStorageKey = 'InstanceSearchFilters';
  const specialSortByKeys = {
    name: 'name',
    companyName: 'companyName'
  }
  
  const specialSortByParamValues = {
    name: 'inst.name',
    companyName: 'comp.name'
  }
  const [searchFilterParams, setSearchFilterParams] = useState({
    sortByParams: specialSortByKeys.name,
    filterByParams: "",
    sortBy: [
        { displayName: 'Name', key: specialSortByKeys.name, value: false },
        { displayName: 'Company name', key: specialSortByKeys.companyName, value: true },
        { displayName: 'Url', key: 'url', value: false },
    ],
    filterBy: [
      { displayName: 'Api enabled', key: 'apiEnabled', value: false },
      { displayName: 'Sabre enabled', key: 'sabreEnabled', value: false }
    ],
    sortDirection: 'Asc'
})

  useEffect(() => {
    const searchFilterInitialState = getSearchFiltersInitialState();
    searchInstances({page, pageSize, searchTerm, sortBy: searchFilterInitialState.sortByParams, filterBy: searchFilterInitialState.filterByParams, sortDirection: searchFilterInitialState.sortDirection});
  }, []);

  async function searchInstances({page, pageSize, searchTerm, sortBy, filterBy, sortDirection}, selectionInstanceIndex, instancesList) {
    let searchResultList = await InstanceService.searchInstances(
      {
        page,
        pageSize,
        searchTerm,
        sortBy: getModifiedSortByParams(sortBy),
        filterBy,
        sortDirection
      }
    );

    if (!searchResultList.data.length && !instancesList?.length) {
      updateInstancesList([]);
      setSelectedInstance('');
      setPage(1);
      setIsRightSpinnerSpinning(false);
      setIsCenterSpinnerSpinning(false);
      setIsBottomSpinnerSpinning(false);
      return;
  }

    if (searchResultList.data?.length) {
      searchResultList.data = getFormattedDatetimeData(searchResultList.data);

      let selectedRecord = null;
      if(instancesList?.length)
      {
       searchResultList.data = searchResultList.data.filter(x => !instancesList.find(y => y.id === x.id));
       searchResultList.data = [...instancesList, ...searchResultList.data];
       selectedRecord = searchResultList.data[selectionInstanceIndex];
      }
      else
      {
          selectionInstanceIndex = searchResultList.data[selectionInstanceIndex]? selectionInstanceIndex: 0;
          searchResultList.data[selectionInstanceIndex].selected = true;
          selectedRecord = searchResultList.data[selectionInstanceIndex];
          setSelectedInstance(selectedRecord);
      }
      
      updateInstancesList(searchResultList.data);

      if(selectedRecord)  getUsersAssocitedWithSelectedInstance(selectedRecord.id)
    }

    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setPage(page + 1);
    setIsCenterSpinnerSpinning(false);
  }

  const getSearchFiltersInitialState = () => {

    const searchFilterState = StorageService.Get(searchFilterLocalStorageKey);

    if (searchFilterState) {
      setSearchFilterParams(searchFilterState);
      return searchFilterState;
    }

    StorageService.Set(searchFilterLocalStorageKey, searchFilterParams);
    return searchFilterParams;
  }

  const getUsersAssocitedWithSelectedInstance = (selectedInstanceId) =>{
    UsersInstanceService.getUsers(selectedInstanceId).then(resp => {
      setSelectedInstanceAssociatedUsers(resp.data);
    })
  }

  const getFormattedDatetimeData = (data) => {
    return data.map((x) => {
      x.createdDate = x.createdDate ? FormatFrmscDateTime(x.createdDate) : "";
      return x;
    });
  };

    const resultListClick = (selectedResultData, index) => {
    let allRows = searchResultListData.map((data, instanceIndex) => {
      data.selected = instanceIndex === index;
      return data;
    });
    updateInstancesList(allRows);
      setSelectedInstance(allRows[index]);
      setState({ ...state, isAddSelected: false });
    getUsersAssocitedWithSelectedInstance(allRows[index].id);
  };

  const searchData = async (searchTerm) => {
    setSelectedInstance("");
    setState({ isEditSelected: false });
    setIsCenterSpinnerSpinning(true);
    let searchResultList = await InstanceService.searchInstances(
      { page: 1,
        pageSize,
        searchTerm,
        sortBy: getModifiedSortByParams(searchFilterParams.sortByParams),
        filterBy: searchFilterParams.filterByParams,
        sortDirection: searchFilterParams.sortDirection
      }
    );
    updateInstancesList(searchResultList.data);
    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setSearchTerm(searchTerm);
    setPage(1);
    setIsCenterSpinnerSpinning(false);
  };

  const loadMore = async () => {
    setIsBottomSpinnerSpinning(true);
    const searchPageNumber = page === 1 ? 2 : page;
    let searchResultList = await InstanceService.searchInstances(
        {
          page: searchPageNumber,
          pageSize,
          searchTerm,
          sortBy: getModifiedSortByParams(searchFilterParams.sortByParams),
          filterBy: searchFilterParams.filterByParams,
          sortDirection: searchFilterParams.sortDirection
        }
    );
    const instancesList = [...searchResultListData, ...searchResultList.data];
    updateInstancesList(instancesList);
    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setPage(searchPageNumber + 1);
    setIsBottomSpinnerSpinning(false);
  };

  const setSelectedIcon = (selectedIcon) => {
    setState({ [selectedIcon]: true });
  };

  const editInstance = () => {
    setSelectedIcon("isEditSelected");
    mapInstanceFormDropdowns();
  };

  const addInstance = () => {
    setSelectedInstance({ instanceKey: GenerateUUID() });
      setSelectedIcon("isAddSelected");
    mapInstanceFormDropdowns();
  };

  const mapInstanceFormDropdowns = () => {
    InstanceService.getInstanceFormData().then(({ data }) => {
      let instanceTypes = parseDropdownData(data.instanceTypes, "instanceTypeDesc", "instanceTypeId");
      let companies = parseDropdownData(data.companies, "name", "id");
      let packages = parseDropdownData(data.packages, "name", "packagesId");
      let servers = parseDropdownData(data.servers, "serverAddress", "id");
      setInstanceEditDropDowns({ instanceTypes, companies, packages, servers });
    });
  }


  const handleSubmit = async (formData) => {
    parseFormValues(formData);
    setIsRightSpinnerSpinning(true);

    let response = null;

    if (state.isAddSelected) {
      response = await InstanceService.addInstance(formData);

    } else {
      response = await InstanceService.updateInstance(formData);

    }

    if(response.data && !response.data.errorMessage) { 
      NotificationService.success(<ClientCreatedSuccessNotification id={response.data.id} secret={response.data.secret} />);
      setAddedClientDetails(response.data);
      setIsClientAddedSuccessfully(true);
    };

    if(response.data && response.data.errorMessage)
    {
      NotificationService.error(response.data.errorMessage);
    }

    setIsRightSpinnerSpinning(false);

    setState({ ...state, isEditSelected: false, isAddSelected: false });
    searchInstances({
      page: 1, 
      pageSize: 10, 
      searchTerm: "",
      sortBy: searchFilterParams.sortByParams,
      filterBy: searchFilterParams.filterByParams,
      sortDirection: searchFilterParams.sortDirection
  });
  };

  const parseFormValues = (formData) => {
    formData.companyId = parseInt(formData.companyId);
    formData.instanceTypeId = parseInt(formData.instanceTypeId);
    formData.packageId = parseInt(formData.packageId);
    formData.createdDate = new Date().toISOString();
  }; 

  const removeUser = (selectedUserIndex, selectedUser) => {

    setIsRightSpinnerSpinning(true);
    UsersInstanceService.deleteUsersInstance(selectedInstance.id, selectedUser.userId).then(resp => {
      
      
      const usersAssociatedToSelectedInstance = [...selectedInstanceAssociatedUsers]; 
      usersAssociatedToSelectedInstance.splice(selectedUserIndex,1);
      setSelectedInstanceAssociatedUsers(usersAssociatedToSelectedInstance);
      setIsRightSpinnerSpinning(false);
    })

  }

  const onToggleAddUserDialog = (isAddUserDialogOpened) => {
    setState({...state, isAddUserDialogOpened});
    setAddUserValidationMessage("")
  }

  const onAddUserSubmit = (data) => {

    data = {
      ...data,
      instanceId: selectedInstance.id
    }

    setIsAddUserDialogSpinnerSpinning(true);
    UsersInstanceService.assignInstanceToUser(data).then(resp => {
      setIsAddUserDialogSpinnerSpinning(false);
      
      if(resp.data.key !== "Error")
      {
        onToggleAddUserDialog(false);
        const updatedUsersList = [resp.data.value, ...selectedInstanceAssociatedUsers, ]; 
        setSelectedInstanceAssociatedUsers(updatedUsersList);
        return;
      }

      setAddUserValidationMessage(resp.data.value);

    })

  }

  const onToggleDeleteInstanceConfirmationPopup = (isButtonClicked) => {
    setState({ ...state, isDeleteInstanceDialogOpened: isButtonClicked});
  }

  const onDeleteInstanceConfirm = () => {
    const  { removedInstanceIndex, updatedInstancesList} = removeSelectedInstanceFromList();
    setIsRightSpinnerSpinning(true);
    onToggleDeleteInstanceConfirmationPopup(false);
    const searchPageNumber = page === 1 ? 2 : page;

    InstanceService.deleteInstance(selectedInstance.id).then(resp => {
        
        searchInstances(
          {
            page: searchPageNumber,
            pageSize,
            searchTerm,
            sortBy: searchFilterParams.sortByParams,
            filterBy: searchFilterParams.filterByParams,
            sortDirection: searchFilterParams.sortDirection
        },
        removedInstanceIndex,
        updatedInstancesList
        );
        setIsRightSpinnerSpinning(false);
    }, (err) => {
        
    })
  }

  const updateInstancesList = (listData) => {
    setSearchResultListData(listData);
    updateListRecordsIndex(listData);
}

const updateListRecordsIndex = (listData) => {
  let recordsIndex = {};
  listData.forEach((x,i) => {
    recordsIndex[x.id] = i;  
  });
  setSearchedListRecordsIndex(recordsIndex);
}

  const removeSelectedInstanceFromList = () => {
    const filteredList  = searchResultListData.filter(x => x.id !== selectedInstance.id);
    let removedInstanceIndex = searchedListRecordsIndex[selectedInstance.id];
    
    if(filteredList?.length)
    {
        removedInstanceIndex = filteredList[removedInstanceIndex]? removedInstanceIndex: removedInstanceIndex - 1; 
        filteredList[removedInstanceIndex].selected = true;
        setSelectedInstance(filteredList[removedInstanceIndex]);
    }

    updateInstancesList(filteredList)

    return { removedInstanceIndex, updatedInstancesList: filteredList};
  }

  const closeClientAddedMessage = () => {
    setIsClientAddedSuccessfully(false);
    setAddedClientDetails("")
  }

  const onSearchFilterChange = (updatedFilterParams) => {
    updatedFilterParams.sortByParams = updatedFilterParams.sortByParams ? updatedFilterParams.sortByParams : specialSortByKeys.name
    setSearchFilterParams(updatedFilterParams);

    searchInstances({
      page: 1, pageSize, searchTerm, sortBy: updatedFilterParams.sortByParams, filterBy: updatedFilterParams.filterByParams,
      sortDirection: updatedFilterParams.sortDirection
    })
  }

  const getModifiedSortByParams = (sortyByKey) => {
    if(specialSortByKeys[sortyByKey]) return specialSortByParamValues[sortyByKey];

    return sortyByKey;
  }

  return (

    <section className="content-box">
      <div className="search-section pt-33">

        <div className='search-box-container'>
        <SearchBox
            placeholder="Search for instances"
            onSearch={searchData}
            shouldUseFilter={true}
            searchFilterParams={searchFilterParams}
            onSearchFilterChange={(updatedFilterParams) => onSearchFilterChange(updatedFilterParams)}
        />
        </div>

        <div className='search-list-container'>

          <div className='search-result-container shadow'>

            <FlatList
              data={searchResultListData}
              leftContentKey="name"
              rightContentKey="createdDate"
              onRowClick={resultListClick}
              hasMoreDataToFetch={hasMoreDataToFetch}
              loadMore={loadMore}
              isFetchingMoreData={isBottomSpinnerSpinning}
              isSearchingData={isCenterSpinnerSpinning}
            />

          </div>
        </div>

      </div>

      <div className="details-section pt-33">
        <div className='action-buttons-section'>

        <div className='left-action-buttons-group'>
                      <div className='mr-5 d-flex '>
                            <IconButton
                                tooltipTitle=""
                                tooltipPostion="top"
                                isActive={state.isAddSelected}
                                onClick={() => addInstance()}
                                name="add"
                                as="button"
                                label="Create instance"
                            />
                      </div>
                  </div>
                  <RenderIf shouldRender={!state.isAddSelected}>
                      <ButtonGroup className="action-buttons-group">

                          <div className="mr-5">
                              <IconButton
                                  tooltipTitle="Chat"
                                  tooltipPostion="top"
                                  isActive={state.isChatSelected}
                                  onClick={() => setSelectedIcon('isChatSelected')}
                                  name="chat"
                                  as="button"
                              />
                          </div>

                          <div className="mr-5">
                              <IconButton
                                  tooltipTitle="Edit"
                                  tooltipPostion="top"
                                  isActive={state.isEditSelected}
                                  onClick={() => editInstance()}
                                  name="edit"
                                  as="button"
                                  disabled={state.isAddSelected}
                              />
                          </div>

                          <div className="mr-5">
                              <IconButton
                                  tooltipTitle="Bookmark"
                                  tooltipPostion="top"
                                  isActive={state.isBookmarkSelected}
                                  onClick={() => setSelectedIcon('isBookmarkSelected')}
                                  name="bookmark"
                                  as="button"
                              />
                          </div>

                          <div className="mr-5">
                              <IconButton
                                  tooltipTitle="Delete Instance"
                                  tooltipPostion="top"
                                  isActive={state.isDeleteInstanceDialogOpened}
                                  onClick={() => setState({isDeleteInstanceDialogOpened: true})}
                                  name="delete"
                                  as="button"
                              />
                          </div>
                      </ButtonGroup>
                      </RenderIf>
                    </div>
         
        <Spinner IsCenterSpinnerSpinning={isRightSpinnerSpinning}></Spinner>

              <div className={`details-section-scroll-under-action-buttons ${state.isAddSelected ? 'mt-30' : ''}`} >
        <div className='details-section-container '>
          <div className='details-container shadow'>

            <div className="row pl-25 pr-25">
              <div className="col-12 col-xl-12 col-xxl-12">

                <RenderIf
                  shouldRender={!state.isEditSelected && !state.isAddSelected && selectedInstance}
                >
                  <InstanceInfo
                    selectedInstance={selectedInstance}
                    onCloseClientAddedMessage={closeClientAddedMessage}
                    addedClientDetails={addedClientDetails}
                    isClientAddedSuccessfully={isClientAddedSuccessfully}
                  />
                </RenderIf>

                <RenderIf
                  shouldRender={state.isEditSelected || state.isAddSelected && selectedInstance}
                >
                  <InstanceForm
                    dropDownsData={instanceEditDropDowns}
                    selectedInstance={selectedInstance}
                    state={state}
                    handleSubmit={handleSubmit}
                  />
                </RenderIf>

                 <RenderIf shouldRender={!state.isAddSelected && selectedInstance}>
                  <div className="row">
                  <div className="content-align-right">
                  <a href="javascript:void(0)" className="text-align-right mr-30 mt-20" onClick={() =>onToggleAddUserDialog(true)}>Add User</a>
                  </div>



                  
                  <Title content="Users" className="mt-30" dark />
                                        
                  <Grid
                    keysToRender={[
                      {key:"email"},
                    ]}
                    data={selectedInstanceAssociatedUsers}
                    headerKeys={[
                      'Email',
                      ''
                    ]}
                    gridButtons={[
                      { name: 'Remove', onClick: removeUser }
                    ]}
                                          />
                                      </div>
                                  </RenderIf>
                  

              

                  

              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <RenderIf shouldRender={state.isAddUserDialogOpened}>
      <Dialog
                  isModalOpen={state.isAddUserDialogOpened}
                  onToggleDialog={(isDialogOpen) => onToggleAddUserDialog(isDialogOpen)}
                  mode="semi-half"
                  >
                    <AddUserForm handleSubmit={onAddUserSubmit} onCancel={() => onToggleAddUserDialog(false)} serverSideValidationMessage={addUserValidationMessage} isSpinnerSpinning={isAddUserDialogSpinnerSpinning}></AddUserForm>

                  </Dialog>
                  </RenderIf>

        <ConfimrationDialog
        isModalOpen={state.isDeleteInstanceDialogOpened}
        onToggleDialog={(isDialogOpen) => onToggleDeleteInstanceConfirmationPopup(isDialogOpen)}
        confimrationMessage={`Do you want to delete instance "${selectedInstance?.name}"?`}
        okBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        title="Delete"
        okBtnStyleType="danger"
        cancelBtnStyleType="primary"
        cancelBtnClick={() => onToggleDeleteInstanceConfirmationPopup(false)}
        okBtnClick={() => onDeleteInstanceConfirm()}
      />
    </section>
  );
};

export default Instances;

const FormField = ({ label, value }) => (
  <div className="form-field">
    <label htmlFor="field">{label}</label>
    {value}
  </div>
);

const InstanceInfo = ({ selectedInstance, onCloseClientAddedMessage, addedClientDetails, isClientAddedSuccessfully }) => (
  <React.Fragment>
  <div className="form-inline">
    <FormField label="Name:" value={selectedInstance.name} />
    <FormField label="App Type:" value={selectedInstance.instanceType} />
    <FormField label="Url:" value={selectedInstance.url} />
    <FormField label="Company:" value={selectedInstance.company} />
    <FormField label="Performance Package:" value={selectedInstance.package} />
    <FormField label="Key:" value={selectedInstance.instanceKey} />
    <FormField label="Api Enabled:" value={selectedInstance.apiEnabled ? "Yes" : "No"} />
    <FormField label="Sabre Enabled:" value={selectedInstance.sabreEnabled ? "Yes" : "No"} />
    <FormField label="Survey Enabled:" value={selectedInstance.surveyEnabled ? "Yes" : "No"} />
    <FormField label="Created Date:" value={selectedInstance.createdDate} />
    <FormField label="Created By:" value={selectedInstance.createdBy} />
    <FormField label="Archive Enabled:" value={selectedInstance.isArchiveEnabled? "Yes" : "No"} />
  </div>

<RenderIf shouldRender={isClientAddedSuccessfully} >
<div className="row">
  <div className="col-6 p-30">
  <ClientCreatedSuccessMessage clientDetails={addedClientDetails} onClose={onCloseClientAddedMessage}/>
  </div>

</div>
</RenderIf>
</React.Fragment>
);

const InstanceForm = ({ dropDownsData, selectedInstance, state, handleSubmit }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Instance name is required."),
    companyId: Yup.string().required("Company is required."),
    packageId: Yup.string().required("Package is required."),
    serverId: Yup.string().required("Server name is required.")
  });

  return (
    <Formik
      initialValues={{
        ...selectedInstance,
        name: selectedInstance.name || "",
        companyId: selectedInstance.companyId || "",
        packageId: selectedInstance.packageId || "",
        apiEnabled: selectedInstance.apiEnabled || false,
        sabreEnabled: selectedInstance.sabreEnabled || false,
        surveyEnabled: selectedInstance.surveyEnabled || false,
        databaseName: selectedInstance.databaseName,
        tier: selectedInstance.tier,
        createdDate: selectedInstance.createdDate,
        createdBy: selectedInstance.createdBy,
        serverId: selectedInstance.serverId || "",
        isArchiveEnabled: selectedInstance.isArchiveEnabled
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      <Form>
        <div className="form-inline">


          <Field component={TextInput} name="name" label="Name:" />

          <Field
            component={Dropdown}
            name="instanceTypeId"
            label="App Type:"
            defaultOption="Select instance"
            options={dropDownsData.instanceTypes}
          />

          <Field component={TextInput} name="url" label="Url:" />

          <Field component={TextInput} name="databaseName" label="Database Name:" />

          <Field
            component={Dropdown}
            name="companyId"
            label="Company:"
            defaultOption="Select company"
            options={dropDownsData.companies}
          />

          <Field
            component={Dropdown}
            name="serverId"
            label="Server:"
            defaultOption="Select server"
            options={dropDownsData.servers}
          />

          <Field
            component={Dropdown}
            name="packageId"
            label="Performance Package:"
            defaultOption="Select package"
            options={dropDownsData.packages}
          />



          <Field
            component={TextInput}
            type="email"
            name="instanceKey"
            label="Key:"
            disabled={true}
          />

          <Field
            name="apiEnabled"
            label="Api Enabled:"
            position="left"
            component={Checkbox}
          />

          <Field
            name="isArchiveEnabled"
            label="Archive Enabled:"
            position="left"
            component={Checkbox}
          />

          <Field
            name="sabreEnabled"
            label="Sabre Enabled:"
            position="left"
            component={Checkbox}
          />

          <Field
            name="surveyEnabled"
            label="Survey Enabled:"
            position="left"
            component={Checkbox}
          />
        </div>
        <div className="content-align-right mt-30">
          <Button text={state.isEditSelected ? "Update" : "Add"} />
        </div>
      </Form>
    </Formik>
  );
};

const AddUserForm = ({handleSubmit, onCancel, serverSideValidationMessage, isSpinnerSpinning}) => {

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required."),
    permission: Yup.string().required("Please select a permission.")
  });

  return (<React.Fragment>
    
    <Formik
      initialValues={{
        email: "",
        permission: ""
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      <Form>
      <Spinner IsCenterSpinnerSpinning={isSpinnerSpinning} /> 
        <div className="row pt-20 p-20">
         
        <Title content="Add User" className="mt-30" dark />
          <Field component={TextInput} name="email" label="Email:" />

          

          <Field
              component={Dropdown}
              name="permission"
              label="Permission:"
              defaultOption="Select permission"
              options={UserComponent.Permissions}
            />

<RenderIf shouldRender={serverSideValidationMessage}>
            <div className="error-message">
            <p>{serverSideValidationMessage}</p>
            </div>
          </RenderIf>

            <div className="content-align-right mt-30">
                <Button
                  type="submit"
                  text="Add"
                />
                <Button
                  type="button"
                  styleType="secondary ml-10"
                  text="Cancel"
                  onClick={onCancel}
                />
              </div>
        </div>
      </Form>
    </Formik>
    </React.Fragment>)

}

const ClientCreatedSuccessNotification = ({id, secret}) => {
  return (
      <div className="row p-10">
        <div className="col-12">
          Client is created successfully for newly added instance. Your client id is <strong>{id}</strong> and your client secret is <strong>{secret}</strong>. <a style={{textDecoration: "underline"}} onClick={() => navigator.clipboard.writeText(secret)}>Copy secret to clipboard</a>
        </div>
        
      </div>
  );
};

const ClientCreatedSuccessMessage = ({ clientDetails, onClose }) => {
  return (
      <div className="row p-10 mt-20" >
        <div className="col-12 details-container shadow" style={{display: 'flex', justifyContent: 'space-between'}} > <span style={{color: 'var(--success)'}}>Client is created successfully. Your client id is <strong>{clientDetails.id}</strong> and your client secret is </span><strong>{clientDetails.secret}</strong>.
        <div className="content-align-right">
                     
        <MdClose size={15} color='black' style={{ marginRight: '1em'}} onClick={onClose} />
                    </div>
        </div>
        
      </div>
  );
};