const Url = {
    company: {
        getAllCompanies: '/company',
        search: '/company/Search',
        updateCompany:'/company',
        addCompany:'/company',
        deleteCompany: '/company/DeleteCompany'
    },
    error: {
        logError: '/error/logError'
    },
    instance: {
        getAllInstances: '/instance/GetAllInstances',
        search: '/instance/Search',
        getInstanceFormData: '/instance/GetInstanceFormData',
        updateInstance: '/instance',
        addInstance: '/instance',
        deleteInstance: '/instance/DeleteInstance'
    },
    account: {
        inviteUser: '/users/inviteUser',
        revokeUser: '/account/RevokeUser'
    },
    users:{
        search: '/account/Search',
        updateUser:'/account',
        getUserDetails: '/account/UserDetails',
        deleteUser: '/account/DeleteUser'
    },
    clients: {
        addClient: '/client',
        getClient: '/client',
        deleteClient: '/client/DeleteClient',
        updateClient: '/client/UpdateClient'
    },
    usersInstance: {
        getUsers: '/usersInstance/GetUsers',
        deleteUsersInstance: '/usersInstance/DeleteUsersInstance',
        assignInstanceToUser: '/usersInstance/AssignInstanceToUser'
    },
    apiScopes: {
        getApiScopes: '/apiScope',
        addApiScope: '/apiScope',
        updateApiScope: '/apiScope/UpdateApiScope',
        deleteApiScope: '/apiScope/DeleteApiScope',
    }

  };
  
  export default Url;