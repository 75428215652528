import {useEffect} from 'react';
import UserManagerService from '../../../services/UserManagerService';
import { Spinner } from '../../../shared/ui-components'

const AuthCallback = (props) => {

  const {push} = props?.history;

    useEffect(() => {
      UserManagerService.signinCallback().then(resp => {
        if(resp)
        {
          push("companies");
        }
      })
    })

    return(
      <Spinner IsCenterSpinnerSpinning={true} SpinnerText="Loading"/>
    )


}

export default AuthCallback;