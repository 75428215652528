import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class InstanceService {
  static getAllInstances = async () => {
    return await axios
      .get(`${baseApiUrl}${Url.instance.getAllInstances}`)
      .then((response) => response);
  };

  static searchInstances = async (searchSortPayload) => {
    return await axios
      .post(
        `${baseApiUrl}${Url.instance.search}`, searchSortPayload
      )
      .then((response) => response);
  };

  static getInstanceFormData = async () => {
    return await axios
      .get(`${baseApiUrl}${Url.instance.getInstanceFormData}`)
      .then((response) => response);
  };

  static updateInstance(instanceInfo) {
    return axios
      .put(`${baseApiUrl}${Url.instance.updateInstance}`, instanceInfo)
      .then((response) => response);
  }

  static addInstance(instanceInfo) {
    return axios
      .post(`${baseApiUrl}${Url.instance.addInstance}`, instanceInfo)
      .then((response) => response);
  }

  static deleteInstance(id) {
    return axios
      .post(`${baseApiUrl}${Url.instance.deleteInstance}/${id}`)
      .then((response) => response);
  }
}
