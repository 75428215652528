import React from "react";

class Tab extends React.Component {
  constructor() {
    super();
    this.state = { selectedTabIndex: 0 };
  }

  onTabSelect = (selectedTab, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
    this.props.onTabSelect(selectedTab, selectedTabIndex);
  };

  render() {
    const { tabs, selectedTabIndex } = this.props;
    let selectedTab = selectedTabIndex ?? this.state.selectedTabIndex;

    return (
      <div style={{position:'relative', zIndex:'2'}}>
      <ul className="tabs">
        {tabs.map((tab, index) => (
          <li key={index}>
            <span
              onClick={() => this.onTabSelect(tab, index)}
              data-tab="Prefrences"
              className={`tab-menu-item cursor-pointer  ${
                selectedTab === index ? "active" : ""
              }`}
            >
              {tab.name}
            </span>
          </li>
        ))}
      </ul>
      </div>
    );
  }
}

export default Tab;
