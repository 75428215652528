const ExtractQueryParams = (query, params) => {
  const queryParams = {};

  params.forEach((param) => {
    const paramValue = new URLSearchParams(query).get(param);
    queryParams[param] = paramValue;
  });

  return queryParams;
};

const GenerateUUID = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const FormatFrmscDateTime = (date) => {
  const day = new Date(date).getDate();
  const month = monthNames[new Date(date).getMonth()];
  const year = new Date(date).getFullYear();

  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();

  const amOrPm = hours >= 12 ? "PM" : "AM";

  return `${day} ${month} ${year} ${hours}:${minutes} ${amOrPm}`;
};

const ParseFrmscDate = (date) => {
  const day = new Date(date).getDate();
  const month = monthNames[new Date(date).getMonth()];
  const year = new Date(date).getFullYear();

  return `${day} ${month} ${year}`;
};

const parseDropdownData = (list, textKey, valueKey) => {
  return list.map((item) => {
    return { text: item[textKey], value: item[valueKey] };
  });
};

export {
  ExtractQueryParams,
  GenerateUUID,
  FormatFrmscDateTime,
  ParseFrmscDate,
  parseDropdownData,
};
