import React, { Component } from "react";

class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  GridRow = () => {
    const { GridData = [] } = this.props;
    return GridData.map((data, index) => {
      index++;
      const isIndexEven = index % 2 === 0;
      const isLastIndex = index === GridData.length;

      return (
        <div key ={`dataGrid${index}`}
          className={
            "dataGridDataRow" + (isIndexEven ? " silverBackground" : "") +
            (isLastIndex? " dataGridBottomBorderRadius": "")
          }
        >
           {Object.keys(data).map(propertyName => <div className="dataGridDataColumn">{data[propertyName]}</div>)}
            
            </div>
      );
    });
  };

  GridHeader = () => {

const { GridHeader } = this.props;

  return <div className="dataGridHeaderRow"> 
  {GridHeader?.length && GridHeader.map(header => <div className="dataGridHeader">{header}</div>)}
   </div>
  }

  render() {
    const { GridData  } = this.props;
    return (
      <div>
        { GridData?.length && <div className="dataGridContainer">
          {this.GridHeader()}
          {this.GridRow()}
        </div>}
      </div>
    );
  }
}
export {DataGrid}