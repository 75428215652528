import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class UsersInstanceService {

  static getUsers = async (instanceId) => {
    return await axios
      .get(`${baseApiUrl}${Url.usersInstance.getUsers}/${instanceId}`)
      .then((response) => response);
  };

  static deleteUsersInstance = async (instanceId, userId) => {
    return await axios
      .get(`${baseApiUrl}${Url.usersInstance.deleteUsersInstance}/${instanceId}/${userId}`)
      .then((response) => response);
  };

  static assignInstanceToUser = async (payloadData) => {
    return await axios
      .post(`${baseApiUrl}${Url.usersInstance.assignInstanceToUser}`, payloadData)
      .then((response) => response);
  };
}
