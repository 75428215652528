import React from "react";

import ErrorMessage from "./ErrorMessage";
import RenderIf from "../render-if/RenderIf";

const Dropdown = ({ removeMargin = false, ...props }) => {
  return (
    <React.Fragment>
      {!removeMargin ? (
        <div className="form-field">
          <Select {...props} />
        </div>
      ) : (
        <Select {...props} />
      )}
    </React.Fragment>
  );
};

export default Dropdown;

const Select = ({
  label,
  options,
  defaultOption,
  field,
  form,
  children,
  isMultiSelect,
  ...props
}) => (
  <React.Fragment>
    {label && <label> {label} </label>}
    <div className="select-field">

      <RenderIf shouldRender={!isMultiSelect}>
      <select {...field} {...props}>
        {defaultOption && <option value="">{defaultOption}</option>}
        {options &&
          options.length &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
      </select>
      </RenderIf>

      <RenderIf shouldRender={isMultiSelect}>
      <select {...field} {...props} multiple>
        {defaultOption && <option value="">{defaultOption}</option>}
        {options &&
          options.length &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
      </select>
      </RenderIf>

      <ErrorMessage form={form} field={field} />
    </div>

    {children}
  </React.Fragment>
);
