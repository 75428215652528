import React from "react";

import RenderIf from "../../render-if/RenderIf";
import Pagination from "../../pagination/Pagination";

const TextAlignPosition = {
  Center: "center",
  Left: "left",
  Right: "right",
};

const getTextAlignment = (position, shouldRenderAsLink) => {
  const labelAsHyperLinkClass = shouldRenderAsLink? " cursor-pointer text-primary": "";

  switch (position) {
    case TextAlignPosition.Center:
      return "text-align-center" + labelAsHyperLinkClass;
    case TextAlignPosition.Left:
      return "text-align-left" + labelAsHyperLinkClass;
    case TextAlignPosition.Right:
      return "text-align-right" + labelAsHyperLinkClass;
    default:
      return "";
  }
};




const Grid = ({ data, keysToIgnore, headerKeys, headerTextAlignment, columnValueAlignment, gridButtons, keysToRender, enablePaging, totalRecordsCount, pageSize, buttonsCount, onPageChange, searchedKeyword, page }) => {
  return (
    <React.Fragment>
      {data && data.length ? (
        <div className={!enablePaging?'table-outer scrollable-grid': 'table-outer'}>
          <table className="table-bordered" cellSpacing="0">
            <GridHeader
              headerTextAlignment={headerTextAlignment}
              headerData={headerKeys}
            />
            <GridBody
              columnValueAlignment={columnValueAlignment}
              data={data}
              keysToIgnore={keysToIgnore}
              keysToRender={keysToRender}
              gridButtons={gridButtons}
            />
          </table>
          <RenderIf shouldRender={enablePaging}>
      <Pagination totalRecordsCount={totalRecordsCount} searchedKeyword={searchedKeyword} pageSize={pageSize} buttonsCount={buttonsCount} onPageChange={onPageChange} pageNumber={page}></Pagination>
      </RenderIf>
        </div>
      ) : (
        ""
      )}
      
    </React.Fragment>
  );
};

export default Grid;

const GridHeader = ({ headerData, headerTextAlignment }) => {
  return (
    <React.Fragment>
      <thead>
        <tr>{headerData && headerData.map((propertyName, headerRowIndex) =>
                  <th
                    key={`grid-header-${headerRowIndex}`}
                    className={
                      headerTextAlignment
                        ? `${getTextAlignment(headerTextAlignment)}`
                        : ""
                    }
                  >
                    {propertyName}
                  </th>  
            )}
        </tr>
      </thead>
    </React.Fragment>
  );
};

const GridBody = ({ data, keysToIgnore, columnValueAlignment, gridButtons, keysToRender }) => {

  const keysToIgnoreExists = keysToIgnore && keysToIgnore.length;
  const keysToRenderExists = keysToRender && keysToRender.length;
  
  return (
    <React.Fragment>
      <tbody>
      <RenderIf shouldRender={keysToRenderExists && !keysToIgnoreExists}>
        {data &&
          data.map((row, index) => (
            <tr style={{overflowWrap: 'break-word'}} key={`grid1-row-${index}`}>{keysToRender?.map((property, rowDataIndex) =>
                    { 
                      return <td
                      key={`grid1-column-${rowDataIndex}`}
                      className={
                        columnValueAlignment
                          ? `${getTextAlignment(columnValueAlignment, property.onClick)}`
                          : property.onClick? " cursor-pointer text-primary"
                          : ""
                      }
                      onClick={() => property.onClick? property.onClick(index, row): null}
                    >
                      {property.formatter? property.formatter(row[property.key]): row[property.key]}
                    </td>}
              )}{gridButtons && gridButtons.map((button, gridBtnIndex) => <td key={`grid1-column-button-${gridBtnIndex}`} ><span className="cursor-pointer text-primary" key={`grid1-column-button-link-${gridBtnIndex}`}  onClick={() => button.onClick? button.onClick(index, row):""}>{button.name}</span></td>)}
            </tr>
          ))}
          </RenderIf>

        <RenderIf shouldRender={!keysToRenderExists && keysToIgnoreExists}>
        {data &&
          data.map((row, index) => (
            <tr style={{overflowWrap: 'break-word'}} key={`grid2-row-${index}`}>{Object.keys(row).map((propertyName, rowDataIndex) =>
                    { const shouldThisKeyBeIgnored = keysToIgnoreExists && keysToIgnore.includes(propertyName);
                      return shouldThisKeyBeIgnored?<React.Fragment key={`grid2-column-${rowDataIndex}`} />:<td
                      key={`grid2-column-${rowDataIndex}`}
                      className={
                        columnValueAlignment
                          ? `${getTextAlignment(columnValueAlignment)}`
                          : ""
                      }
                    >
                      {row[propertyName]}
                    </td>}
              )}{gridButtons && gridButtons.map((button, gridBtnIndex) => <td key={`grid2-column-button-${gridBtnIndex}`}><span className="cursor-pointer text-primary" key={`grid2-column-button-link-${gridBtnIndex}`} onClick={() => button.onClick? button.onClick(index):""}>{button.name}</span></td>)}
            </tr>
          ))}
          </RenderIf>
      </tbody>
    </React.Fragment>
  );
};
