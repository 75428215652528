import {useEffect} from 'react';
import UserManagerService from '../../../services/UserManagerService';
import { Spinner } from '../../../shared/ui-components'
import { RouteConstants } from '../../../shared';
import { AuthService } from '../../../services';

const LoginRedirectionCallback = (props) => {

  const {push} = props?.history;

    useEffect(async() => {
    const isUserSessionExpired = AuthService.isUserSessionExpired();

    if(isUserSessionExpired){
        await UserManagerService.signinRedirect();
        return;
    }

    push(RouteConstants.RedirectPaths.Dashboard);
    })

    return(
      <Spinner IsCenterSpinnerSpinning={true} SpinnerText="Loading"/>
    )


}

export default LoginRedirectionCallback;