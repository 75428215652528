
import { UserManager, WebStorageStateStore } from 'oidc-client';

import AppConfig from '../AppConfig'

const { frmscIdentityUrl, hostUrl } = AppConfig;

const UserManagerService = new UserManager({                    
  client_id:"react_frmsc_admin",
  authority: frmscIdentityUrl,
  response_type:"code",
  scope: 'openid profile admin.api.scope frmsc.admin.company.scope offline_access',
  redirect_uri: `${hostUrl}/auth-callback`,//"https://localhost:44396/auth-callback",
  post_logout_redirect_uri: `${hostUrl}/signout-callback`, //"https://localhost:44396/signout-callback",
  response_mode:'query',
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  accessTokenExpiringNotificationTime: 60 // Default time prior to refresh access token
});

const onAccessTokenExpiring = () => {
  UserManagerService.signinSilent();
}

UserManagerService.events.addAccessTokenExpiring(onAccessTokenExpiring);

export default UserManagerService;