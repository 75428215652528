import axios from "axios";
import { Url } from "../shared/constants";

const baseIdentityApiUrl = process.env.REACT_APP_IDENTITY_API_URL;//"https://localhost:44323";


export default class AccountService {
  static inviteUser(payload) {
      return axios
          .post(`${baseIdentityApiUrl}${Url.account.inviteUser}`, payload)
          .then((response) => response);

    }

  static getClientIpInfo() {
    return fetch('https://jsonip.com', { mode: 'cors' })
    .then((resp) => resp.json());
  }

  static revokeUserSession(userId) {
    console.log(`${baseIdentityApiUrl}${Url.account.revokeUser}?userId=${userId}`);
    return axios
          .get(`${baseIdentityApiUrl}${Url.account.revokeUser}?userId=${userId}`)
    .then((resp) => resp);
  }

}
