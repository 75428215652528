import React from "react";

const Position = {
  Outside: "outside",
  Inside: "inside",
};

const CardType = {
  Normal: "normal",
  Shadow: "shadow",
};

const Card = ({
  title,
  type,
  fullHeight,
  position = Position.Outside,
  className,
  ...restProps
}) => {
  const getClassName = () => {
    switch (type) {
      case CardType.Normal:
        return fullHeight ? "border-box h-full" : "border-box";
      case CardType.Shadow:
        return fullHeight ? "shadow-box h-full" : "shadow-box";
        default:
          return fullHeight ? "border-box h-full" : "border-box";
    }
  };

  return (
    <React.Fragment>
      {position === Position.Outside && <CardTitle title={title} />}

      <div className={getClassName? getClassName() +" " + className: getClassName()}>
        {position === Position.Inside && <CardTitle title={title} />}
        {restProps.children}
      </div>
    </React.Fragment>
  );
};

Card.defaultProps = {
  type: "normal",
  fullHeight: false,
};

export default Card;

const CardTitle = ({ title }) => (
  <> {title && <div className="title">{title}</div>}</>
);
