import React from "react";

const Button = ({ text, onClick, type, styleType, disabled }) => {
  return (
    <button
      type={type}
      className={`button btn-${styleType}`}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

Button.defaultProps = {
  text: "Submit",
  type: "submit",
  styleType: "primary",
};

export default Button;
