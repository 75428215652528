import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import { AddEditClients } from "../containers";
import { ClientsService, NotificationService, ApiScopesService } from "../../../services";
import { parseDropdownData } from '../../../shared/Utility'
import { Spinner, RenderIf, SearchBox, FlatList, IconButton, ButtonGroup, GenericCard, ConfimrationDialog } from "../../../shared/ui-components";

const Clients = () => {

  const [searchResultListData, setSearchResultListData] = useState([]);
  const [searchResultListDataCopy, setSearchResultListDataCopy] = useState([]);
  const [searchedListRecordsIndex, setSearchedListRecordsIndex] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [apiScopesDropdownData, setApiScopesDropdownData] = useState([]);

  const [isSearchListSpinnerSpinning, setIsSearchListSpinnerSpinning] = useState(false)
  const [isRightSectionSpinnerSpinning, setIsRightSectionSpinnerSpinning] = useState(false);

  const [isClientAddedSuccessfully, setIsClientAddedSuccessfully] = useState(false);
  const [addedClientSecret, setAddedClientSecret] = useState("");

  const [state, setState] = useState({
    isAddSelected: false,
    isEditSelected: false,

    isDeleteClientDialogOpened: false
  })

  useEffect(() => {
    getApiScopes();
    getClients();
  },[])

  const getClients = async (selectionClientIndex, clientsList) => {

    setIsSearchListSpinnerSpinning(true);
    setIsRightSectionSpinnerSpinning(true);

    let searchResultList = await ClientsService.getClients();
    
    if (!searchResultList.data.length && !clientsList?.length) {
        updateClientsList([]);
        setSearchResultListDataCopy([]);
        setSelectedClient('');
        setIsSearchListSpinnerSpinning(false);
        setIsRightSectionSpinnerSpinning(false);
        return;
    }

    let selectedRecord = null;
    if(clientsList?.length)
    {
      searchResultList.data = searchResultList.data.filter(x => !clientsList.find(y => y.id === x.id));
      searchResultList.data = [...clientsList, ...searchResultList.data];
      selectedRecord = searchResultList.data[selectionClientIndex];
    }
    else
    {
        selectionClientIndex = searchResultList.data[selectionClientIndex]? selectionClientIndex: 0;
        searchResultList.data[selectionClientIndex].selected = true;
        selectedRecord = searchResultList.data[selectionClientIndex];
        setSelectedClient(selectedRecord);
    }
    
    updateClientsList(searchResultList.data);
    setSearchResultListDataCopy(searchResultList.data);

    setIsSearchListSpinnerSpinning(false);
    setIsRightSectionSpinnerSpinning(false);
    
}

const getApiScopes = () => {
  ApiScopesService.getApiScopes().then(resp => {
    const apiScopes = parseDropdownData(resp.data, 'name','name')
    setApiScopesDropdownData(apiScopes);
  })
}


  const onAddClientSubmit = async (values) => {
    setIsRightSectionSpinnerSpinning(true)
      await ClientsService.addClient(values).then(async (resp) => {
          NotificationService.success(<ClientCreatedSuccessNotification secret={resp.data} />);
          setIsRightSectionSpinnerSpinning(false);

          onCancelAddEditClient();
          await getClients();
          setIsClientAddedSuccessfully(true);
          setAddedClientSecret(resp.data);
      }).catch((error) => {
          // Handle error
          if (error && error.errorDetails && error.errorDetails.ErrorMessage) {
              NotificationService.error(error.errorDetails.ErrorMessage);
          }
          setIsRightSectionSpinnerSpinning(false);
      });
  };

  const onEditClientSubmit = async (values) => {
    setIsRightSectionSpinnerSpinning(true)
    await ClientsService.updateClient(values).then((resp) => {
      setIsRightSectionSpinnerSpinning(false);
      onCancelAddEditClient();
      getClients();
    });
  };

  const closeClientAddedMessage = () => {

    setIsClientAddedSuccessfully(false);
    setAddedClientSecret("")
  }

  const onIconButtonToggle = (selectedIcon) => {
    
    const isIconSelected = !state[selectedIcon]

    setState({[selectedIcon]: isIconSelected});

    closeClientAddedMessage();

    if(!state.isAddSelected) return;
    
    if(isIconSelected)
    {
      setSelectedClient(null)
      
      if(searchResultListData.length)
      {
        const clientsList = [...searchResultListData];
        clientsList.forEach(x => {
           x.selected = false
        });

        updateClientsList(clientsList)
      }
    }
    else
    {
      searchResultListData.length && onRowSelection(searchResultListData[0], 0);
    }

  }

  const onRowSelection = async (selectedRowData, index) => {

    setIsRightSectionSpinnerSpinning(true)
    let allRows = searchResultListData.map((data, clientsIndex) => {
        data.selected = clientsIndex === index
        return data
    })

    updateClientsList(allRows);
    setSelectedClient(selectedRowData);

    setIsRightSectionSpinnerSpinning(false)

    setState({
        ...state,
        isAddSelected: false,
        isEditSelected: false
    })

  }

  const searchData = async (searchTerm) => {
    setSelectedClient('')
    setState({ isEditSelected: false })
    setIsSearchListSpinnerSpinning(true)
    let searchResultList =  searchResultListDataCopy.filter(x => x.clientId.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)

    if(searchResultList.length)
    {
      searchResultList.forEach(x => x.selected = false);
      searchResultList[0].selected = true;
      setSelectedClient(searchResultList[0]);
    }

    updateClientsList(searchResultList)
    setSearchTerm(searchTerm)
    setIsSearchListSpinnerSpinning(false)
  }

  const updateListRecordsIndex = (listData) => {
    let recordsIndex = {};
    listData.forEach((x,i) => {
      recordsIndex[x.id] = i;  
    });
    setSearchedListRecordsIndex(recordsIndex);
  }

  const updateClientsList = (listData) => {
    setSearchResultListData(listData);
    updateListRecordsIndex(listData);
}

  const removeSelectedClientFromList = () => {
        
    const filteredList  = searchResultListData.filter(x => x.id !== selectedClient.id);
    let removedClientIndex = searchedListRecordsIndex[selectedClient.id];
    
    if(filteredList?.length)
    {
        removedClientIndex = filteredList[removedClientIndex]? removedClientIndex: removedClientIndex - 1; 
        filteredList[removedClientIndex].selected = true;
        setSelectedClient(filteredList[removedClientIndex]);
    }

    updateClientsList(filteredList)

    return {removedClientIndex, updatedClientsList: filteredList};
  }

  const onToggleDeleteClientConfirmationPopup = (isButtonClicked) => {
    setState({ ...state, isDeleteClientDialogOpened: isButtonClicked});
}

  const onDeleteClientConfirm = () => {
    const  {removedClientIndex, updatedClientsList} = removeSelectedClientFromList();
    setIsRightSectionSpinnerSpinning(true);
    onToggleDeleteClientConfirmationPopup(false);
    
    ClientsService.deleteClient(selectedClient.id).then(resp => {
        
        getClients(
        removedClientIndex,
        updatedClientsList
        );
        setIsRightSectionSpinnerSpinning(false);
    }, (err) => {
        
    })
  }

  const onCancelAddEditClient = () => {
    
    setState({
      isAddSelected: false,
      isEditSelected: false
    })

    if(searchResultListData.length)
    {
      const clientsList = [...searchResultListData];

      clientsList.forEach(x => {
        x.selected = false
     });

     clientsList[0].selected = true;

      updateClientsList(clientsList);
      setSelectedClient(clientsList[0]);
    }
  }

  return (
    <section className="content-box">
      <div className="search-section pt-33">

        <div className='search-box-container'>

        <SearchBox
                        placeholder="Search for clients"
                        onSearch={searchData}
                    />

        </div>

        <div className='search-list-container'>

          <div className='search-result-container shadow'>

            <FlatList
            data={searchResultListData}
            renderItem={(data, index) => (
              <GenericCard
                key={index}
                rostersIndex={index}
                onClick={onRowSelection}
                isActive={data.selected}
                data={data}
                
                leftContentKey="clientName"
              />
            )}
            isSearchingData={isSearchListSpinnerSpinning}
            />

          </div>
        </div>
      </div>

      <div className="details-section pt-33">
        <div className='action-buttons-section'>

        <div className='left-action-buttons-group'>
                        <div className='mr-5 d-flex '>
                            <IconButton
                                tooltipTitle=""
                                tooltipPostion="top"
                                isActive={state.isAddSelected}
                                onClick={() => onIconButtonToggle('isAddSelected')}
                                name="add"
                                as="button"
                                label="Create client"
                            />
                        </div>
                    </div>

          <ButtonGroup className="action-buttons-group">

          <div className="mr-5">
              <IconButton
                  tooltipTitle="Edit"
                  tooltipPostion="top"
                  isActive={state.isEditSelected}
                  onClick={() => onIconButtonToggle('isEditSelected')}
                  name="edit"
                  as="button"
                  disabled={state.isAddSelected}
              />
          </div>

            <div className="mr-5">
              <IconButton
                tooltipTitle="Delete Client"
                tooltipPostion="top"
                isActive={state.isDeleteClientDialogOpened}
                onClick={() => setState({isDeleteClientDialogOpened: true})}
                name="delete"
                as="button"
              />
            </div>
          </ButtonGroup>


        </div>

        <Spinner
          IsCenterSpinnerSpinning={
            isRightSectionSpinnerSpinning
          }
        />


<div className="details-section-scroll-under-action-buttons">
        <div className='details-section-container '>
          <div className='details-container shadow'>

            <div className="row pl-25 pr-25">
              <div className="col-12 col-xl-12 col-xxl-12">

                <RenderIf
                  shouldRender={!state.isEditSelected && !state.isAddSelected && selectedClient}
                >
                  <ClientInfo
                  addedClientSecret={addedClientSecret}
                  isClientAddedSuccessfully={isClientAddedSuccessfully}
                  onCloseClientAddedMessage={closeClientAddedMessage}
                    selectedClient={selectedClient}
                  />
                </RenderIf>

                <RenderIf
                  shouldRender={state.isAddSelected || state.isEditSelected}
                >
                  <AddEditClients
                  apiScopesDropdownData={apiScopesDropdownData}
                  onCancel={() => onCancelAddEditClient()}
                  isAddForm={state.isAddSelected}
                  onAddSubmit={onAddClientSubmit}
                  onEditSubmit={onEditClientSubmit}
                  selectedClient={selectedClient}
                  />

                  
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
        </div>

      </div>

      <ConfimrationDialog
        isModalOpen={state.isDeleteClientDialogOpened}
        onToggleDialog={(isDialogOpen) => onToggleDeleteClientConfirmationPopup(isDialogOpen)}
        confimrationMessage={`Do you want to delete client "${selectedClient?.clientId}"?`}
        okBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        title="Delete Client"
        okBtnStyleType="danger"
        cancelBtnStyleType="primary"
        cancelBtnClick={() => onToggleDeleteClientConfirmationPopup(false)}
        okBtnClick={() => onDeleteClientConfirm()}
      />

    </section>
    // <div class="details-section-full">
    //   <div class="details-section-container ">
    //     <div class="details-container shadow">
    //       <Spinner IsCenterSpinnerSpinning={isSpinnerSpinning} />
    //       <AddEditClients
    //         isAddForm={true}
    //         shouldUseClientCredentialFlowOnly={true}
    //         onAddSubmit={onAddClientSubmit}
    //       ></AddEditClients>
          
    //       <RenderIf shouldRender={isClientAddedSuccessfully} >
    //       <div className="row">
    //         <div className="col-6 p-30">
    //         <ClientCreatedSuccessMessage secret={addedClientSecret} onClose={closeClientAddedMessage}/>
    //         </div>
          
    //       </div>
    //       </RenderIf>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Clients;

const ClientCreatedSuccessNotification = ({ secret }) => {
  return (
      <div className="row p-10">
        <div className="col-12">
          Client is created successfully. Your client secret is <strong>{secret}</strong>. <a style={{textDecoration: "underline"}} onClick={() => navigator.clipboard.writeText(secret)}>Copy secret to clipboard</a>
        </div>
        
      </div>
  );
};

const ClientCreatedSuccessMessage = ({ secret, onClose }) => {
  return (
      <div className="row p-10 mt-20" >
        <div className="col-12 details-container shadow" style={{display: 'flex', justifyContent: 'space-between'}} > <span style={{color: 'var(--success)'}}>Client is created successfully. Your client secret is</span><strong>{secret}</strong>.
        <div className="content-align-right">
                     
        <MdClose size={15} color='black' style={{ marginRight: '1em'}} onClick={onClose} />
                    </div>
        </div>
        
      </div>
  );
};

const FormField = ({ label, value }) => (
  <div className="form-field">
      <label htmlFor="field">{label}</label>
      {value}
  </div>
)

const ClientInfo = ({ selectedClient, isClientAddedSuccessfully, addedClientSecret, onCloseClientAddedMessage }) => {
  
  const convertBooleanToDisplayText = (value) => {
       return value? "Yes": "No"
  }

  return <div className="row pt-25">
          <div className="form-inline col-4 pl-20">
              <FormField label="Id:" value={selectedClient.clientId} />
              <FormField label="Name:" value={selectedClient.clientName} />
              <FormField label="Secret:" value={selectedClient.clientSecret} />
              <FormField label="Grant Type:" value={selectedClient.grantType} />
              <FormField label="Allowed Access Token Via Browser:" value={convertBooleanToDisplayText(selectedClient.isAllowedAccessTokenViaBrowser)} />
              <FormField label="Client Secret Required:" value={convertBooleanToDisplayText(selectedClient.isClientSecretRequired)} />
              <FormField label="Allowed Scopes:" value={selectedClient.allowedScopes.join(",")} />
              <FormField label="Allowed Cors Origins:" value={selectedClient.allowedCorsOrigins} />
              <FormField label="Redirect Urls:" value={selectedClient.redirectUrls} />
              <FormField label="Post Logout Redirect Urls:" value={selectedClient.postLogoutRedirectUrls} />
              <FormField label="Allowed Offline Access:" value={convertBooleanToDisplayText(selectedClient.isAllowedOfflineAccess)} />
              <FormField label="Consent Required:" value={convertBooleanToDisplayText(selectedClient.isConsentRequired)} />
              <FormField label="Refresh Token Expiration Type:" value={selectedClient.refreshTokenExpirationType} />
              <FormField label="Absolute Refresh Token Lifetime:" value={selectedClient.absoluteRefreshTokenLifetime} />
              <FormField label="Sliding Refresh Token Lifetime:" value={selectedClient.slidingRefreshTokenLifetime} />
              <FormField label="Access Token Lifetime:" value={selectedClient.accessTokenLifetime} />
              <FormField label="Always Send Client Claims:" value={convertBooleanToDisplayText(selectedClient.isAlwaysSendClientClaims)} />
              <FormField label="PKCE Required:" value={convertBooleanToDisplayText(selectedClient.isPkceRequired)} />
          </div>

          <RenderIf shouldRender={isClientAddedSuccessfully} >
                      <div className="row">
                        <div className="col-6 p-30">
                        <ClientCreatedSuccessMessage secret={addedClientSecret} onClose={onCloseClientAddedMessage}/>
                        </div>
                
                      </div>
                    </RenderIf>
  </div>
}


