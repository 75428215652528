import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Companies } from "../../modules/companies";
import { Dashboard } from "../../modules/dashboard";
import { Packages } from "../../modules/packages";
import { Connected } from "../../modules/connected";
import { Instances } from "../../modules/instances";
import { Users } from "../../modules/users";
import { Clients } from '../../modules/clients';
import { ApiScopes } from "../../modules/apiScopes";

import ProtectedRoute from "./ProtectedRoute";

const protectRoute = (ChildComponent, childProps) => (
  <ProtectedRoute>
    <ChildComponent {...childProps} />
  </ProtectedRoute>
);

const homeRoutes = () => (
  <React.Fragment>
    <Switch>
      <Route exact path="/dashboard" children={(componentProps) =>
            protectRoute(Dashboard, componentProps)
          } />

      <Route exact path="/companies" children={(componentProps) =>
            protectRoute(Companies, componentProps)
          } />

      <Route exact path="/instances" children={(componentProps) =>
            protectRoute(Instances, componentProps)
          } />

      <Route exact path="/users" children={(componentProps) =>
            protectRoute(Users, componentProps)
          } />

      <Route exact path="/connected" children={(componentProps) =>
            protectRoute(Connected, componentProps)
          }/>

      <Route exact path="/packages" children={(componentProps) =>
            protectRoute(Packages, componentProps)
          }/>

      <Route exact path="/clients" children={(componentProps) =>
            protectRoute(Clients, componentProps)
          }/>

      <Route exact path="/api-scopes" component={ApiScopes} children={(componentProps) =>
            protectRoute(ApiScopes, componentProps)
          }/>
          
      <Redirect to={{ pathname: "/dashboard" }} />
    </Switch>
  </React.Fragment>
);

export default homeRoutes;
