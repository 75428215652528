import React, { useState } from "react";

import { BsFilter, BsFilterCircleFill } from 'react-icons/bs'

import SvgIcon from '../svg-icons/SvgIcon'
import PopupButton from '../popup-button/PopupButton';

import { RenderIf } from "..";
import { SearchFilter } from "..";


const SearchBox = ({ placeholder, onSearch, popupMenuOptions, onAddClick, iconButtons, shouldUseFilter, searchFilterParams, onSearchFilterChange, onSortDirectionChange, isAddBtnHidden,  shouldShowBtnsWithinSearchBox }) => {

    const [isFilterButtonSelected, setIsFilterButtonSelected] = useState(false);

    const onToggleFilterButtonSelection = () => {
        setIsFilterButtonSelected(!isFilterButtonSelected);
    }

    return (
        <div className="input-search-group">
            <input
                type="text"
                className="input-control input-search input-rounded shadow"
                placeholder={placeholder}
                onChange={(e) => onSearch && onSearch(e.target.value)}
                style={{ fontSize: "0.8rem", flexBasis: shouldShowBtnsWithinSearchBox? '90%': '100%' }}
            />


            <RenderIf shouldRender={!isFilterButtonSelected && shouldUseFilter && searchFilterParams}>
                <BsFilter size={20} className='search-box-filter' style={{right: shouldShowBtnsWithinSearchBox? '50px': '15px'}} color="#72a2e2" onClick={() => onToggleFilterButtonSelection()}></BsFilter>
            </RenderIf>
            {/* <BsFilter size={20} style={{position: 'absolute', right:  '45px'}}></BsFilter> */}
            <RenderIf shouldRender={isFilterButtonSelected && shouldUseFilter && searchFilterParams}>
                <BsFilterCircleFill size={20} className='search-box-filter' style={{right: shouldShowBtnsWithinSearchBox? '50px': '15px'}} color="#72a2e2" onClick={() => onToggleFilterButtonSelection()}></BsFilterCircleFill>
            </RenderIf>

            <RenderIf shouldRender={isFilterButtonSelected && shouldUseFilter && searchFilterParams}>
                <SearchFilter sortByParams={searchFilterParams?.sortBy}
                    filterByParams={searchFilterParams?.filterBy}
                    sortByDirection={searchFilterParams?.sortDirection}
                    onToggleSearchFilter={onToggleFilterButtonSelection}
                    onChange={onSearchFilterChange}
                />
            </RenderIf>
            <RenderIf shouldRender={!isAddBtnHidden}>
                 

                    {iconButtons && iconButtons.length && iconButtons.map((iconButton, index) =>
                    <span style={{ display: 'flex' }} className="ml-10"> <React.Fragment key={index}>{iconButton.component}</React.Fragment> </span>)}

            </RenderIf>
        </div>

    );
};

SearchBox.defaultProps = {
    placeholder: "Search for content",
};

export default SearchBox;
