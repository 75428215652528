import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './app/pages';
import { SignIn, AuthCallback, SignOutCallback, LoginRedirectionCallback } from './app/modules/auth';
import { RouteConstants } from './app/shared';
import UnauthorizedAccessFallbackPage from './UnauthorizedAccessFallbackPage';

const routes = () => (
  
    <Router>
      <Switch>
      <Route path='/sign-in' component={SignIn} />
      <Route path='/auth-callback' component={AuthCallback} />
      <Route path='/signout-callback' component={SignOutCallback} />
      <Route exact path={`/${RouteConstants.RedirectPaths.LoginRedirectionCallbackPage}`} component={LoginRedirectionCallback} />
      <Route exact path={`/${RouteConstants.RedirectPaths.UnAuthorizedAccessFallbackPage}`} component={UnauthorizedAccessFallbackPage} />
      <Route path='/' component={Home} />
      </Switch>
    </Router>
  
);

export default routes;