import AppConfig from "../AppConfig";
import { Constants } from "../shared";

export default class AuthService {

    
  static getUserSessionData = () => {
    const userSessionDataKey  = `oidc.user:${AppConfig.frmscIdentityUrl}:react_frmsc_admin`;
    return JSON.parse(localStorage.getItem(userSessionDataKey));
  }

  static isUserIsAdmin() {
    const userSessionData = AuthService.getUserSessionData();
    if(!userSessionData) return false;

    let isUserAdminKeyValue = userSessionData.profile[Constants.UserRoleKeys.isAdmin];

if (typeof isUserAdminKeyValue == 'undefined') return false;


    return isUserAdminKeyValue.toLowerCase() === 'yes';
  }

  static isTokenTimedOut = (expirationTimeStamp) => {
   const currentDateTime = new Date();
   const isTokenExpired = new Date(expirationTimeStamp * 1000) < currentDateTime;
   return isTokenExpired;
  }

  static isUserSessionExpired() {
    const userSessionData = AuthService.getUserSessionData();
    if(!userSessionData) return true;

    return AuthService.isTokenTimedOut(AuthService.getUserSessionData().expires_at);
  }
}