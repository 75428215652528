import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class UsersService {
    static searchUsers = async (searchSortPayload)=> {
        return await axios
          .post(
            `${baseApiUrl}${Url.users.search}`,searchSortPayload
          )
          .then((response) => response);
      };

      static updateUser(userInfo) {
        return axios
          .put(`${baseApiUrl}${Url.users.updateUser}`, userInfo)
          .then((response) => response);
      }

      static getUserDetails(userId) {
        return axios
          .get(`${baseApiUrl}${Url.users.getUserDetails}/${userId}`)
          .then((response) => response);
      }

      static deleteUser(userId) {
        return axios
          .get(`${baseApiUrl}${Url.users.deleteUser}/${userId}`)
          .then((response) => response);
      }
}