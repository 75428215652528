import React from 'react'

class Header extends React.Component {

  render() {

    return (
      <header className="no-shadow border-bottom">
        <div className="theme-header" >
          <div className="header-left">{this.props.children}</div>
        </div>
      </header>
    )
  }
}

export default Header
