import React from 'react'

import Dialog from './Dialog'

const ConfimrationDialog = ({
  onToggleDialog,
  isModalOpen,
  children,
  shouldNotShowButtons,
  okBtnText,
  cancelBtnText,
  okBtnClick,
  cancelBtnClick,
  mode = 'semi-half',
  style,
  confimrationMessage,
  title,
  okBtnStyleType,
  cancelBtnStyleType
}) => {
  const dialogProps = {
    onToggleDialog,
    isModalOpen,
    children,
    shouldNotShowButtons,
    okBtnText,
    cancelBtnText,
    okBtnClick,
    cancelBtnClick,
    mode,
    style,
    title,
    okBtnStyleType,
    cancelBtnStyleType
  }

  return (
    <Dialog {...dialogProps}>
      <div className="mt-20">
      <h4 className="tab-title">{title}</h4>
      <div className="divider-line" />
      <h6 className="mt-50">{confimrationMessage}</h6>
      </div>
    </Dialog>
  )
}

export default ConfimrationDialog
