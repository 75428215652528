import React from "react";
import {  Redirect } from "react-router-dom";
import { AuthService } from "../../services";
import { RouteConstants } from "../../shared/constants";

const ProtectedRoute = ({ children }) => {

    const isUserSessionExpired = AuthService.isUserSessionExpired();
    const isUserAdmin = AuthService.isUserIsAdmin();
    
    if (isUserSessionExpired) {
        return <Redirect to={RouteConstants.RedirectPaths.LoginRedirectionCallbackPage} />
    }
    
    if(!isUserAdmin)
    {
        return <Redirect to={RouteConstants.RedirectPaths.UnAuthorizedAccessFallbackPage} />;
    }   
  
    return children;
 
};

export default ProtectedRoute;