const STATUS_CODE = {
  UN_AUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500
};

const UserRoleKeys = {
  isAdmin: 'isAdminUser'
}


const Constants = {
  STATUS_CODE,
  UserRoleKeys
};

export default Constants;
