import React from 'react'

const ButtonGroupTabLabelPosition = {
  Right: 'right',
  Left: 'left',
}

const ButtonGroupTab = ({
  label,
  position,
  data,
  ...props
}) => {

    

  return (
    <React.Fragment>
        
          {label && position === ButtonGroupTabLabelPosition.Left && (
            <p className="inline-small-title">{label}</p>
          )}
          <ul className="button-group-tab-inline-check">
            {data && data.length && data.map( (x, index) => (
              <li key={index}>
                <ButtonGroup {...props} index={index} text={x?.text} value={x?.value} />
              </li>
            ))}
          </ul>
          {/* <div style={{height:"20px", width:"100%"}}></div> */}
          {label && position === ButtonGroupTabLabelPosition.Right && (
            <p className="inline-small-title">{label}</p>
          )}
          <div className="" style={{flex:1, display:"flex",  paddingTop:"20px", height: '100%'}}>
              {<SelectedButtonGroupComponent data={data} field={props.field} />}
          </div>
    </React.Fragment>
  )
}

ButtonGroupTab.defaultProps = {
    position: "left",
};

export default ButtonGroupTab

const SelectedButtonGroupComponent = ({data, field}) => {
    return (data && data.find(x => x.value === field.value).component) || ("");
}

const ButtonGroup = ({ value, text, index, field, ...restProps }) => {
  const id = `${value}${index}${text}`
  return (
    <>
      <input {...field} id={id} type="radio" checked={field?.value === value} value={value} autoComplete="off" />
      <label htmlFor={id}>{text}</label>
    </>
  )
}
