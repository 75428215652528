import React from 'react'
import ToolTip from '../toolTip/ToolTip';
import { FaBeer, FaKey } from 'react-icons/fa';
import {  IoPricetagOutline } from 'react-icons/io5';
import { BsBookmarkDash, BsChatDots, BsBookmarkDashFill, BsFilePdf } from 'react-icons/bs';
import { RiEditLine, RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlineAdd, MdOutlineFileDownload, MdOutlineShare, MdOutlineHistory, MdUnarchive, MdLogout } from 'react-icons/md';
import { RenderIf } from '..';
import { BiWindows, BiCopy, BiUser } from 'react-icons/bi'
import { FiMaximize, FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { GrDocumentPdf } from 'react-icons/gr';
import { FcKey } from 'react-icons/fc';




const IconButton = ({
  tooltipTitle,
  tooltipPostion,
  tooltipBackgroundColor,
  onTooltipMouseEnter,
  onTooltipMouseLeave,
  name,
  as,
  color,
  onClick,
  className,
  disabled,
  isActive,
  isHover,
  children,
  iconColor,
  label
}) => {

  // const svgIconProps = {
  //   name,
  //   as,
  //   color,
  //   onClick,
  //   className,
  //   disabled,
  //   isActive,
  //   isHover
  // };

  const fontSize = '1.3rem';  

  const onBtnClick = () => {

    if(disabled)
    {
      return;
    }
    onClick();

  }

  return tooltipTitle ? (
    <div className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  }`}>
      <ToolTip
        position={tooltipPostion}
        backgroundColor={tooltipBackgroundColor}
        title={tooltipTitle}
        disabled = {disabled}
      >
        <div
          style={{
            height: '2rem',
            width: '2rem',
            textAlign: 'center',
            lineHeight: '2rem',
          }}
          onMouseEnter={onTooltipMouseEnter}
          onMouseLeave={onTooltipMouseLeave}
        >
          <div
            style={{ height: '100%', width: '100%' }}
            onClick={() => onClick && onBtnClick()}
            className={` icon-button ${className ?? ''}  ${isActive ? 'icon-button-active' : ''}  `}
          >
                  
<RenderIf shouldRender={children}>{children}</RenderIf>
                      <RenderIf shouldRender={!children}><Icon fontSize={fontSize}  name={name} color={iconColor} /></RenderIf>
                      
                      
          </div>
           
        </div>
      </ToolTip>
    </div>
  ) : (     
    <div
    onClick={() => onClick && onBtnClick()}
    className={`  icon-button ${className ?? ''}  ${isActive ? 'icon-button-active' : ''} ${disabled ? ' cursor-not-allowed' : ' cursor-pointer'} ${label ? ' action-button-with-label d-flex': ''} `}>
      <Icon fontSize={fontSize} name={name} color={iconColor} className='d-flex'  />
      {label && <span className='text-accent ml-5'>{label}</span>}
    </div>
  )
}

export default IconButton;

const Icon = ({ fontSize, name, color, className }) => (
  <div>
    {name === 'add' && <MdOutlineAdd fontSize={fontSize} color={color} className={className} />}
    {name === 'delete' && <RiDeleteBin6Line fontSize={fontSize} color={color} className={className} />}
    {name === 'chat' && <BsChatDots fontSize={fontSize} color={color} className={className} />}
    {name === 'download' && <MdOutlineFileDownload fontSize={fontSize} color={color} className={className} />}
    {name === 'edit' && <RiEditLine fontSize={fontSize} color={color} className={className} />}
    {name === 'bookmark' && <BsBookmarkDash fontSize={fontSize} color={color} className={className} />}
    {name === 'aeroplaneTakeOff' && <FaBeer fontSize={fontSize} color={color} className={className} />}                      
    {/* {name == 'message' && <FaBeer fontSize={fontSize} />} */}
    {name === 'privacy' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'onePerson' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'upload' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'battery' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'aeroplaneOverEarth' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'tod' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'sod' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'eod' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'percentage' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'hrs' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'dot' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'cross' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'addFolder' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'trash' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'archive' && <FaBeer fontSize={fontSize}  color={color} className={className} />}
    {name === 'share' && <MdOutlineShare fontSize={fontSize}  color={color} className={className} />}
    {name === 'tag' && <IoPricetagOutline fontSize={fontSize}  color={color} className={className} />}
    {name === 'bookmarkFilled' && <BsBookmarkDashFill fontSize={fontSize}  color={color} className={className} />}
    {name === 'openNewTab' && <BiWindows fontSize={fontSize}  color={color} className={className} />}
    {name === 'version' && <MdOutlineHistory fontSize={fontSize}  color={color} className={className} />}
    {name === 'copy' && <BiCopy fontSize={fontSize}  color={color} className={className} />}
    {name === 'maximize' && <FiMaximize2 fontSize={fontSize}  color={color} className={className} />}
    {name === 'minimize' && <FiMinimize2 fontSize={fontSize}  color={color} className={className} />}
    {name === 'pdf' && <GrDocumentPdf fontSize={fontSize}  color={color} className={className} />}
    {name === 'unarchive' && <MdUnarchive fontSize={fontSize}  color={color} className={className} />}
    {name === 'logout' && <MdLogout fontSize={fontSize}  color={color} className={className} />}
    {name === 'change-password' && <FcKey fontSize={fontSize}  color={color} className={className}  />}
    {name === 'users' && <BiUser fontSize={fontSize}  color={color} className={className}  />}
  </div>
)