import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import { AddEditScopes } from "../containers";
import { ApiScopesService } from "../../../services";
import { Spinner, RenderIf, SearchBox, FlatList, IconButton, ButtonGroup, GenericCard, ConfimrationDialog } from "../../../shared/ui-components";

const ApiScopes = () => {

  const [searchResultListData, setSearchResultListData] = useState([]);
  const [searchResultListDataCopy, setSearchResultListDataCopy] = useState([]);
  const [searchedListRecordsIndex, setSearchedListRecordsIndex] = useState({});
  const [selectedScope, setSelectedScope] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [isSearchListSpinnerSpinning, setIsSearchListSpinnerSpinning] = useState(false)
  const [isRightSectionSpinnerSpinning, setIsRightSectionSpinnerSpinning] = useState(false);


  const [state, setState] = useState({
    isAddSelected: false,
    isEditSelected: false,

    isDeleteScopeDialogOpened: false
  })

  useEffect(() => {
    getApiScopes();
  },[])

  const getApiScopes = async (selectionScopeIndex, scopesList) => {

    setIsSearchListSpinnerSpinning(true);
    setIsRightSectionSpinnerSpinning(true);

    let searchResultList = await ApiScopesService.getApiScopes();
    
    if (!searchResultList.data.length && !scopesList?.length) {
        updateScopesList([]);
        setSearchResultListDataCopy([]);
        setSelectedScope('');
        setIsSearchListSpinnerSpinning(false);
        setIsRightSectionSpinnerSpinning(false);
        return;
    }

    let selectedRecord = null;
    if(scopesList?.length)
    {
      searchResultList.data = searchResultList.data.filter(x => !scopesList.find(y => y.id === x.id));
      searchResultList.data = [...scopesList, ...searchResultList.data];
      selectedRecord = searchResultList.data[selectionScopeIndex];
    }
    else
    {
        selectionScopeIndex = searchResultList.data[selectionScopeIndex]? selectionScopeIndex: 0;
        searchResultList.data[selectionScopeIndex].selected = true;
        selectedRecord = searchResultList.data[selectionScopeIndex];
        setSelectedScope(selectedRecord);
    }
    
    updateScopesList(searchResultList.data);
    setSearchResultListDataCopy(searchResultList.data);

    setIsSearchListSpinnerSpinning(false);
    setIsRightSectionSpinnerSpinning(false);
}


  const onAddScopeSubmit = async (values) => {
    setIsRightSectionSpinnerSpinning(true)
    await ApiScopesService.addApiScope(values).then((resp) => {
      setIsRightSectionSpinnerSpinning(false);
      onCancelAddEditScope();
      getApiScopes();
    });
  };

  const onEditScopeSubmit = async (values) => {
    setIsRightSectionSpinnerSpinning(true)
    await ApiScopesService.updateApiScope(values).then((resp) => {
      setIsRightSectionSpinnerSpinning(false);
      onCancelAddEditScope();
      getApiScopes();
    });
  };


  const onIconButtonToggle = (selectedIcon) => {
    const isIconSelected = !state[selectedIcon]

    setState({[selectedIcon]: isIconSelected});

    if(isIconSelected && selectedIcon !== 'isAddSelected') return;
    
    if(isIconSelected)
    {
      setSelectedScope(null)
      
      if(searchResultListData.length)
      {
        const scopesList = [...searchResultListData];
        scopesList.forEach(x => {
           x.selected = false
        });

        updateScopesList(scopesList)
      }
    }
    else
    {
      searchResultListData.length && onRowSelection(searchResultListData[0], 0);
    }

  }

  const onRowSelection = async (selectedRowData, index) => {

    setIsRightSectionSpinnerSpinning(true)
    let allRows = searchResultListData.map((data, scopesIndex) => {
        data.selected = scopesIndex === index
        return data
    })

    updateScopesList(allRows);
    setSelectedScope(selectedRowData);

    setIsRightSectionSpinnerSpinning(false)

    setState({
        ...state,
        isAddSelected: false,
        isEditSelected: false
    })

  }

  const searchData = async (searchTerm) => {
    setSelectedScope('')
    setState({ isEditSelected: false })
    setIsSearchListSpinnerSpinning(true)
    let searchResultList =  searchResultListDataCopy.filter(x => x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)

    if(searchResultList.length)
    {
      searchResultList.forEach(x => x.selected = false);
      searchResultList[0].selected = true;
      setSelectedScope(searchResultList[0]);
    }

    updateScopesList(searchResultList)
    setSearchTerm(searchTerm)
    setIsSearchListSpinnerSpinning(false)
  }

  const updateListRecordsIndex = (listData) => {
    let recordsIndex = {};
    listData.forEach((x,i) => {
      recordsIndex[x.id] = i;  
    });
    setSearchedListRecordsIndex(recordsIndex);
  }

  const updateScopesList = (listData) => {
    setSearchResultListData(listData);
    updateListRecordsIndex(listData);
}

  const removeSelectedScopeFromList = () => {
    const filteredList  = searchResultListData.filter(x => x.id !== selectedScope.id);
    let removedScopeIndex = searchedListRecordsIndex[selectedScope.id];
    
    if(filteredList?.length)
    {
        removedScopeIndex = filteredList[removedScopeIndex]? removedScopeIndex: removedScopeIndex - 1; 
        filteredList[removedScopeIndex].selected = true;
        setSelectedScope(filteredList[removedScopeIndex]);
    }

    updateScopesList(filteredList)

    return {removedScopeIndex: removedScopeIndex, updatedScopesList: filteredList};
  }

  const onToggleDeleteScopeConfirmationPopup = (isButtonClicked) => {
    setState({ ...state, isDeleteScopeDialogOpened: isButtonClicked});
}

  const onDeleteScopeConfirm = () => {
    const  {removedScopeIndex, updatedScopesList} = removeSelectedScopeFromList();
    setIsRightSectionSpinnerSpinning(true);
    onToggleDeleteScopeConfirmationPopup(false);
    
    ApiScopesService.deleteApiScope(selectedScope.id).then(resp => {
        
        getApiScopes(
        removedScopeIndex,
        updatedScopesList
        );
        setIsRightSectionSpinnerSpinning(false);
    }, (err) => {
        
    })
  }

  const onCancelAddEditScope = () => {
    
    setState({
      isAddSelected: false,
      isEditSelected: false
    })

    if(searchResultListData.length)
    {
      const scopesList = [...searchResultListData];

      scopesList.forEach(x => {
        x.selected = false
     });

     scopesList[0].selected = true;

      updateScopesList(scopesList);
      setSelectedScope(scopesList[0]);
    }
  }

  return (
    <section className="content-box">
      <div className="search-section pt-33">

        <div className='search-box-container'>

        <SearchBox
                        placeholder="Search for scopes"
                        onSearch={searchData}
                    />

        </div>

        <div className='search-list-container'>

          <div className='search-result-container shadow'>

            <FlatList
            data={searchResultListData}
            renderItem={(data, index) => (
              <GenericCard
                key={index}
                rostersIndex={index}
                onClick={onRowSelection}
                isActive={data.selected}
                data={data}
                
                leftContentKey="name"
              />
            )}
            isSearchingData={isSearchListSpinnerSpinning}
            />

          </div>
        </div>
      </div>

      <div className="details-section pt-33">
        <div className='action-buttons-section'>

        <div className='left-action-buttons-group'>
                        <div className='mr-5 d-flex '>
                            <IconButton
                                tooltipTitle=""
                                tooltipPostion="top"
                                isActive={state.isAddSelected}
                                onClick={() => onIconButtonToggle('isAddSelected')}
                                name="add"
                                as="button"
                                label="Create scope"
                            />
                        </div>
                    </div>

          <ButtonGroup className="action-buttons-group">

          <div className="mr-5">
              <IconButton
                  tooltipTitle="Edit"
                  tooltipPostion="top"
                  isActive={state.isEditSelected}
                  onClick={() => onIconButtonToggle('isEditSelected')}
                  name="edit"
                  as="button"
                  disabled={state.isAddSelected}
              />
          </div>

            <div className="mr-5">
              <IconButton
                tooltipTitle="Delete Scope"
                tooltipPostion="top"
                isActive={state.isDeleteScopeDialogOpened}
                onClick={() => setState({isDeleteScopeDialogOpened: true})}
                name="delete"
                as="button"
              />
            </div>
          </ButtonGroup>


        </div>

        <Spinner
          IsCenterSpinnerSpinning={
            isRightSectionSpinnerSpinning
          }
        />


<div className="details-section-scroll-under-action-buttons">
        <div className='details-section-container '>
          <div className='details-container shadow'>

            <div className="row pl-25 pr-25">
              <div className="col-12 col-xl-12 col-xxl-12">

                <RenderIf
                  shouldRender={!state.isEditSelected && !state.isAddSelected && selectedScope}
                >
                  <ScopeInfo
                    selectedScope={selectedScope}
                  />
                </RenderIf>

                <RenderIf
                  shouldRender={state.isAddSelected || state.isEditSelected}
                >
                  <AddEditScopes
                  onCancel={() => onCancelAddEditScope()}
                  isAddForm={state.isAddSelected}
                  onAddSubmit={onAddScopeSubmit}
                  onEditSubmit={onEditScopeSubmit}
                  selectedScope={selectedScope}
                  />

                  
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
        </div>

      </div>

      <ConfimrationDialog
        isModalOpen={state.isDeleteScopeDialogOpened}
        onToggleDialog={(isDialogOpen) => onToggleDeleteScopeConfirmationPopup(isDialogOpen)}
        confimrationMessage={`Do you want to delete scope "${selectedScope?.name}"?`}
        okBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        title="Delete"
        okBtnStyleType="danger"
        cancelBtnStyleType="primary"
        cancelBtnClick={() => onToggleDeleteScopeConfirmationPopup(false)}
        okBtnClick={() => onDeleteScopeConfirm()}
      />

    </section>
  );
};

export default ApiScopes;

const FormField = ({ label, value }) => (
  <div className="form-field">
      <label htmlFor="field">{label}</label>
      {value}
  </div>
)

const ScopeInfo = ({ selectedScope }) => {
  
  const convertBooleanToDisplayText = (value) => {
       return value? "Yes": "No"
  }

  return <div className="row pt-25">
          <div className="form-inline col-4 pl-20">
              <FormField label="Name:" value={selectedScope.name} />
              <FormField label="Display Name:" value={selectedScope.displayName} />
              <FormField label="Description:" value={selectedScope.description} />
              <FormField label="Enabled:" value={convertBooleanToDisplayText(selectedScope.enabled)} />
              <FormField label="Required:" value={convertBooleanToDisplayText(selectedScope.required)} />
              <FormField label="Emphasize:" value={convertBooleanToDisplayText(selectedScope.emphasize)} />
              <FormField label="Show In Discovery Document:" value={convertBooleanToDisplayText(selectedScope.showInDiscoveryDocument)} />
              <FormField label="Show In AUD Claim:" value={convertBooleanToDisplayText(selectedScope.showInAudClaim)} />
          </div>
  </div>
}


