import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class ClientsService {

      static addClient(client) {
        return axios
          .post(`${baseApiUrl}${Url.clients.addClient}`, client)
          .then((response) => response);
      }

      static getClients() {
        return axios
          .get(`${baseApiUrl}${Url.clients.getClient}`)
          .then((response) => response);
      }

      static updateClient(client) {
        return axios
          .post(`${baseApiUrl}${Url.clients.updateClient}`, client)
          .then((response) => response);
      }

      static deleteClient(id) {
        return axios
          .get(`${baseApiUrl}${Url.clients.deleteClient}/${id}`)
          .then((response) => response);
      }
}