import React, { useRef, useEffect } from "react";
import { InfiniteScroll } from '../../infiniteScroll/InfiniteScroll';
import { Spinner } from '../../spinner/Spinner';

import useOnScreen from "../../../../hooks/useOnScreen.";

const FlatList = ({
  data,
  leftContentKey,
  rightContentKey,
  renderItem,
  renderWhenEmpty = null,
  onRowClick,
  loadMore,
  hasMoreDataToFetch,
  isFetchingMoreData,
  isSearchingData
}) => {
  const isFunction = (item) => typeof item === "function";
  const listRef = useRef();
  const { measureRef, isIntersecting, observer } = useOnScreen();
  const renderBlank = () =>
    renderWhenEmpty && isFunction(renderWhenEmpty) ? (
      renderWhenEmpty()
    ) : renderWhenEmpty ? (
      <renderWhenEmpty.type {...renderWhenEmpty.props} />
    ) : (
      <DefaultBlank />
    );

    useEffect(() => {
        if (isIntersecting && hasMoreDataToFetch) {
            loadMore();
            observer.disconnect();
        }
    }, [isIntersecting, hasMoreDataToFetch])

  const handleRenderItem = (item, key, index, onRowSelection) => {
    if (!renderItem && !leftContentKey && !rightContentKey) {
      return null;
    }

    if (isFunction(renderItem)) {
      return renderItem(item, index);
    }

    return (
      <DefaultRow
        key={key}
        idx={index}
        rowData={item}
        leftContentKey={leftContentKey}
        rightContentKey={rightContentKey}
        onRowClick={(selectedRow, rowIndex) =>
          onRowClick(selectedRow, rowIndex)
        }
      />
    );
  };

  return (
    <div>
          <ul className="vertical-list" >
              {
                  data && data.length
                      ? data.map((item, index) => {

                          const isLastItem = index === data.length - 1;

                          if (isLastItem) {

                              return (
                                  <div key={item.id ?? index} ref={measureRef}>
                                      {handleRenderItem(item, item.id ?? index, index)}
                                  </div>
                              );
                          }

                          return (
                                 handleRenderItem(item, item.id ?? index, index)
                          );
                      })
                      : renderBlank()
              }

      </ul>
           <Spinner IsBottomSpinnerSpinning={isFetchingMoreData} IsCenterSpinnerSpinning={isSearchingData} />
   </div >
  );
};

export default FlatList;

const DefaultBlank = () => <label>List has no items</label>;

class DefaultRow extends React.Component {

  render() {
    const { idx, leftContentKey, rightContentKey, onRowClick, rowData } = this.props;

    return (
      <li
        key={`${idx}-${Math.random()}`}
        className={`vertical-item cursor-pointer ${rowData.selected ? "active" : ""}`}
        onClick={() => onRowClick(rowData, idx)}
      >
        <span className="vertical-name"> {rowData[leftContentKey]} </span>
        <span className="vertical-date">{rowData[rightContentKey]}</span>
      </li>
    );
  }
}
