import axios from "axios";

import UserManagerService from "../src/app/services/UserManagerService";

import { Constants } from "./app/shared/constants";

const { STATUS_CODE } = Constants;

const ifUnauthorizedRequest = (responseStatusCode) => {
  return (
    responseStatusCode === STATUS_CODE.UN_AUTHORIZED ||
    responseStatusCode === STATUS_CODE.FORBIDDEN
  );
};

axios.interceptors.request.use(
  async (request) => {
    const { access_token = "" } = (await UserManagerService.getUser()) || {};
    const bearerToken = `Bearer ${access_token}`;

    request.headers.Authorization = bearerToken;

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorMessage = "";
    const { status, data } = error?.response || {};

    if (ifUnauthorizedRequest(status)) {
      UserManagerService.getUser().then(resp => {
        if(resp)
        {
        UserManagerService.revokeAccessToken().then(resp => {
          UserManagerService.signinRedirect();
        })
        }
        else
        {
          UserManagerService.signinRedirect();
        }
      })
      
    } else {
      errorMessage =
        status !== STATUS_CODE.INTERNAL_SERVER_ERROR
          ? extractErrors(data)
          : "An internal server error occurred";
    }

    return Promise.reject({ errorMessage, status, errorDetails: data });
  }
);

function extractErrors(data) {
  let error = "";

  if (data.hasOwnProperty("ErrorMessage")) {
    error = data.Errors.length ? data.Errors.toString() : data.ErrorMessage;
  } else {
    error = data;
  }

  return error;
}
