import {  toast } from 'react-toastify';
import {  FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

export default class NotificationService {

    static success(notificationMessage) {
        toast.success(notificationMessage, {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: <MdClose size={20} color='black' style={{alignSelf: 'center', marginRight: '1em'}} />,
            icon: <FaCheckCircle size={40} color='rgb(0,165,121)' />
            })
    }

    static error(notificationMessage) {
        toast.error(notificationMessage, {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: <MdClose size={20} color='black' style={{alignSelf: 'center', marginRight: '1em'}} />,
            icon: <FaExclamationCircle size={40} color='red' />
            })
    }
  
}