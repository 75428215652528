import React from "react";
import { RenderIf } from "..";

import ErrorMessage from "./ErrorMessage";

const TextInput = ({ removeMargin = false, ...props }) => {
  return (
    <React.Fragment>
      {!removeMargin ? (
        <div className="form-field">
          <Input {...props} />
        </div>
      ) : (
        <Input {...props} />
      )}
    </React.Fragment>
  );
};

export default TextInput;

const Input = ({
  label,
  type = "text",
  children,
  field,
  form,
  className,
  disabled,
  ...props
}) => {  
  let isDisabled = disabled ? "disabled" : "";
  let disabledClass = disabled ? "cursor-not-allowed" : "";

  return (
    <React.Fragment>
      <label> {label} </label>
      <div className="input-field">
        <input
          type={type}
          {...field}
          {...props}
          tabIndex={props.tabIndex? props.tabIndex: ""}
          className={`input-control ${className??''} ${disabledClass} ${props.uuid}`}
          disabled={isDisabled}
        />
        <RenderIf shouldRender={form && field}>
        <ErrorMessage form={form} field={field} />
        </RenderIf>
      </div>
      {children}
    </React.Fragment>
  );
};
