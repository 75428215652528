import React, { useEffect, useState } from "react";

import { Header } from "./app/shared/ui-components";

const headerLogo = require("./app/assets/images/frmscLogo.png").default;
const unAuthorizedLogo = require("./app/assets/images/un-authorized_logo.png").default;

const UnauthorizedAccessFallbackPage = () => {


  return (
    <div className="content-wrapper w-100">
      <Header>
        <img alt="Header Logo" src={headerLogo} width="50" />
        <div className="header-title">FRMSc</div>
      </Header>

      <div className="content-box d-flex justify-content-center">
        <div className="row">
          <div className="col-12  col-lg-5">
            <img className="error-logo" alt="Error Logo" src={unAuthorizedLogo} />
          </div>

          <div className="col-12  col-lg-7 text-align-center p-60">
            <div className="error-logo-heading-text p-60">Oops!</div>
            <div className="error-logo-message-description">
              We’re sorry, but you are not authorized to access the page you requested.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccessFallbackPage;
