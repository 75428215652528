import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class SideNavigationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarExpanded: props.isExpanded,
    };
  }

  onSideBarToggle() {
    this.setState(
      (prevState) => ({
        isSidebarExpanded: !prevState.isSidebarExpanded,
      }),
      () => {
        // To add active class in content-wrapper
        this.props.onSideBarToggle(this.state.isSidebarExpanded);
      }
    );
  }

  render() {
    const { isSidebarExpanded } = this.state;

    return (
      <div className={`sidebar ${isSidebarExpanded ? "" : "active"}`}>
        <div className="sidebar-body">
          <SidebarHeader {...this.props} />
          <div className="sidebar-content">
            <SidebarNavMenu {...this.props} />
            <LeftArrowIcon onSideBarToggle={this.onSideBarToggle.bind(this)} />
          </div>
        </div>

        <SidebarFooter {...this.props} />
      </div>
    );
  }
}

export { SideNavigationPanel };

const SidebarHeader = ({ SideNavTitle, SideNavLogo }) => (
  <div className="sidebar-logo">
    <div className="sidebar-logo-container">
      <div className="logo-shape">
        <img alt="Side Nav Logo" src={SideNavLogo} width="40" height="40" />
        <div className="logo-text">{SideNavTitle}</div>  
      </div>      
      
      <div className="logo-text-right">{SideNavTitle}</div>
    </div>
  </div>
);

const SidebarNavMenu = ({ SideNavOptions }) => {
  return (
    <ul className="side-menu-list">
      {SideNavOptions?.length &&
        SideNavOptions.map((option, index) => (
          <li key={index} className="side-menu-item">
            <NavLink
              to={option.path}
              className="side-menu-link side-menu-item"
              activeClassName=" active"
            >
              <div className="smi-icon-container">
                <i className="smi-icon">{option.icon}</i>
              </div>
              <span className="sml-text">{option.title}</span>
            </NavLink>
          </li>
        ))}
    </ul>
  );
};

const LeftArrowIcon = ({ onSideBarToggle }) => (
  <div className="sidebar-toggler">
    <button
      onClick={() => onSideBarToggle()}
      id="sidebarToggle"
      className="sidebar-toggle-btn"
      type="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="37"
        viewBox="0 0 38 37"
        fill="none"
      >
        <path
          d="M25 13L19.2841 18.5L25 24"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7158 13L11.9999 18.5L17.7158 24"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </div>
);

const SidebarFooter = ({
  SideNavBottomOptions,
  SideNavBottomTitle,
  OnBottomNavTitleClick,
}) => (
  <div className="sidebar-footer">
    <div className="footer-title cursor-pointer">
      <span onClick={() => OnBottomNavTitleClick()}>
        {" "}
        {SideNavBottomTitle}{" "}
      </span>
    </div>
    <ul className="side-menu-list">
      {SideNavBottomOptions?.length &&
        SideNavBottomOptions.map((option, index) => (
          <li key={index} className="side-menu-item">
            <NavLink
              to={option.path}
              className="side-menu-link side-menu-item"
              activeClassName=" active"
            >
              <div className="smi-icon-container">
              <i className="smi-icon">{option.icon}</i>
              </div>
              <span className="sml-text">{option.title}</span>
            </NavLink>
          </li>
        ))}
    </ul>
  </div>
);

// import React, { Component } from 'react';
// import { ArrowRightSquare, ArrowLeftSquare } from 'react-iconly';

// import './SideNavigationPanel.css';
// const DEFAULT_BACKGROUND_COLOR = '#72A2E2'

// classNameNameName SideNavigationPanel extends Component {

//     constructor(props) {
//         super(props);
//         const { DefaultSelectedOptionIndex = '' } = props;
//         this.state = {
//             isExpanded: true,
//             selectedSideNavOptionIndex: DefaultSelectedOptionIndex,
//             selectedSideNavBottomOptionIndex: '',
//         }
//         this.onNavOptionClick = this.onNavOptionClick.bind(this);
//     }

//     onNavOptionClick(index, optionsType) {
//         debugger;
//         const { SideNavOptions, SideNavBottomOptions } = this.props;
//         const { onSideNavOptionClick, title, path } = optionsType ? SideNavBottomOptions[index] || {} : SideNavOptions[index] || {};

//         const selectedOptionIndex = {
//             selectedSideNavOptionIndex: optionsType ? '' : index,
//             selectedSideNavBottomOptionIndex: optionsType ? index : ''
//         }

//         this.setState(selectedOptionIndex);
//         onSideNavOptionClick(title, path);
//     }

//     SideNavBar() {
//         const { SideNavOptions, BackgroundColor } = this.props;
//         return (
//             <div id="sideNavPanel" classNameNameNameName={"sideNavPanel" + (!this.state.isExpanded ? " sideNavPanelcollapsed" : "")} style={{ backgroundColor: BackgroundColor ? BackgroundColor : DEFAULT_BACKGROUND_COLOR }} >
//                 <div classNameNameNameName="sideNavPanelContainer">
//                     {this.SideNavHeader()}
//                     <div classNameNameNameName="sideNavOptionsContainer" style={{ padding: this.state.isExpanded ? "2.5%" : "10.5%" }}>

//                         {SideNavOptions?.length && SideNavOptions.map((option, index) => (
//                             <div key={`option-${index}`} classNameNameNameName={`sideNavOption  sideNavTextWhite` + (!this.state.isExpanded ? " sideNavJustifyCenter" : "") + (this.state.selectedSideNavOptionIndex === index ? " sideNavSelected" : "")} onClick={() => this.onNavOptionClick(index)}>
//                                 <div classNameNameNameName="sideNavOptionIcon sideNavTextWhite">
//                                     {option.icon}
//                                 </div>

//                                 {this.state.isExpanded && <div classNameNameNameName="sideNavOptionName">{option.title}</div>}

//                             </div>
//                         ))}

//                         <div classNameNameNameName={"sideNavCollapseBtnContainer sideNavTextWhite" + (!this.state.isExpanded ? " sideNavAlignSelfCenter" : "")} style={{ width: !this.state.isExpanded ? "100%" : "15%" }} onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
//                             {this.state.isExpanded && <ArrowLeftSquare set="curved" primaryColor="white" size="medium" />}
//                             {!this.state.isExpanded && <ArrowRightSquare set="curved" primaryColor="white" size="medium" />}
//                         </div>
//                     </div>
//                     {this.SideNavFooter()}
//                 </div>
//             </div>)
//     }

//     SideNavHeader() {
//         const { SideNavLogo, SideNavTitle } = this.props;
//         return (
//             <div classNameNameNameName={"sideNavHeaderContainer" + (!this.state.isExpanded ? " sideNavFlexColumn sideNavCenterAlign" : "")}>
//                 <div classNameNameNameName={"sideNavLogoContainer" + (!this.state.isExpanded ? " sideNavCenterAlign" : "")}>
//                     <img classNameNameNameName="sideNavLogo" src={SideNavLogo}></img>
//                 </div>
//                 <div classNameNameNameName={"sideNavTitleHeaderContainer" + (!this.state.isExpanded ? " sideNavCenterAlign" : "")} style={{ paddingLeft: this.state.isExpanded ? "6%" : 0 }}>
//                     <h5>{SideNavTitle}</h5>
//                 </div>
//             </div>
//         )
//     }

//     SideNavFooter() {
//         const { SideNavBottomOptions, SideNavBottomTitle, OnBottomNavTitleClick } = this.props;
//         return (

//             <div classNameNameNameName="sideNavFooterContainer " style={{ padding: this.state.isExpanded ? "2.5%" : "10.5%" }}>
//                 <div classNameNameNameName={"sideNavTitleHeaderContainer"} style={{ paddingLeft: this.state.isExpanded ? '4%' : "12%" }}>
//                     <a onClick={() => OnBottomNavTitleClick()}><h5>{SideNavBottomTitle}</h5></a>
//                 </div>

//                 <div classNameNameNameName="sideNavBottomOptionsContainer">

//                     {SideNavBottomOptions?.length && SideNavBottomOptions.map((option, index) => (
//                         <div key={`option-${index}`} classNameNameNameName={`sideNavBottomOption  sideNavTextWhite` + (!this.state.isExpanded ? " sideNavJustifyCenter" : "") + (this.state.selectedSideNavBottomOptionIndex === index ? " sideNavSelected" : "")} onClick={() => this.onNavOptionClick(index, "bottom")}>
//                             <div classNameNameNameName="sideNavOptionIcon sideNavTextWhite">
//                                 {option.icon}
//                             </div>

//                             {this.state.isExpanded && <div classNameNameNameName="sideNavOptionName">{option.appType}</div>}

//                         </div>
//                     ))}

//                 </div>

//             </div>

//         )
//     }

//     render() {
//         return (
//             this.SideNavBar()
//         )

//     }

// }

// export { SideNavigationPanel };
