import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import "../styles/companies.css";
import {ConfimrationDialog} from "../../../shared/ui-components";

import {
    RenderIf,
    SearchBox,
    ButtonGroup,
    Card,
    FlatList,
    TextInput,
    Button,
    Checkbox,
    IconButton,
    Spinner
} from '../../../shared/ui-components';

import { CompanyService, StorageService } from "../../../services";

const Companies = (props) => {
    const [selectedCompany, setSelectedCompany] = useState({});
    const [searchResultListData, setSearchResultListData] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize] = useState(25);
    const [hasMoreDataToFetch, setHasMoreDataToFetch] = useState(true);
    const [isCenterSpinnerSpinning, setIsCenterSpinnerSpinning] = useState(true);
    const [isBottomSpinnerSpinning, setIsBottomSpinnerSpinning] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [state, setState] = useState({
        isEditSelected: false,
        isBookmarkSelected: false,
        isChatSelected: false,
        isAddSelected: false,
        isDeleteSelected:false
    });
    const [searchedListRecordsIndex, setSearchedListRecordsIndex] = useState({});
    const searchFilterLocalStorageKey = 'CompanySearchFilters';
    const [searchFilterParams, setSearchFilterParams] = useState({
        sortByParams: "name",
        filterByParams: "",
        sortBy: [
            { displayName: 'Name', key: 'name', value: true },
            { displayName: 'Date Created', key: 'dateCreated', value: false }
        ],
        filterBy: [],
        sortDirection: 'Asc'
    })

    useEffect(() => {
        const searchFilterInitialState = getSearchFiltersInitialState();
        searchCompanies({page, pageSize, searchTerm, sortBy: searchFilterInitialState.sortByParams, filterBy: searchFilterInitialState.filterByParams, sortDirection: searchFilterInitialState.sortDirection});
    }, []);

    async function searchCompanies({page, pageSize, searchTerm, sortBy, filterBy, sortDirection}, selectionCompanyIndex, companiesList) {     
        let searchResultList = await CompanyService.searchCompanies(
            {
                page,
                pageSize,
                searchTerm,
                sortBy,
                filterBy,
                sortDirection
            }
        );

        if (!searchResultList.data.length && !companiesList?.length) {
        updateCompaniesList([]);
        setSelectedCompany('');
        setPage(1);
        setIsCenterSpinnerSpinning(false);
        setIsBottomSpinnerSpinning(false);
        return;
        }

        if(searchResultList.data?.length) {

        let selectedRecord = null;
        
        if (companiesList?.length) {
            searchResultList.data = searchResultList.data.filter(x => !companiesList.find(y => y.id === x.id));
            searchResultList.data = [...companiesList, ...searchResultList.data];
            selectedRecord = searchResultList.data[selectionCompanyIndex];
        }
        else {
            selectionCompanyIndex = searchResultList.data[selectionCompanyIndex] ? selectionCompanyIndex : 0;
            searchResultList.data[selectionCompanyIndex].selected = true;
            selectedRecord = searchResultList.data[selectionCompanyIndex];
            setSelectedCompany(selectedRecord);
        }
      
      updateCompaniesList(searchResultList.data);
    }

    setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
    setPage(page + 1);
    setIsCenterSpinnerSpinning(false);
    }

    const getSearchFiltersInitialState = () => {

        const searchFilterState = StorageService.Get(searchFilterLocalStorageKey);
    
        if (searchFilterState) {
          setSearchFilterParams(searchFilterState);
          return searchFilterState;
        }
    
        StorageService.Set(searchFilterLocalStorageKey, searchFilterParams);
        return searchFilterParams;
    }

    const onSearchFilterChange = (updatedFilterParams) => {
        updatedFilterParams.sortByParams = updatedFilterParams.sortByParams ? updatedFilterParams.sortByParams : 'name'
        setSearchFilterParams(updatedFilterParams);
    
        searchCompanies({
          page: 1, pageSize, searchTerm, sortBy: updatedFilterParams.sortByParams, filterBy: updatedFilterParams.filterByParams,
          sortDirection: updatedFilterParams.sortDirection
        })
    }

    const resultListClick = (selectedResultData, rowIndex) => {
       
        let allRows = searchResultListData.map(({ id, name, dateCreated, twoFactorEnabled, createdBy }, index) => {
            return { id, name, dateCreated, twoFactorEnabled, createdBy, selected: rowIndex === index };
        });
        updateCompaniesList(allRows);
        setSelectedCompany(selectedResultData);

        if (state.isAddSelected)
            setState({ ...state, isAddSelected: false, isEditSelected: true });
    };

    const searchData = async (searchTerm) => {
        setSelectedCompany("");
        setState({ isEditSelected: false });
        setIsCenterSpinnerSpinning(true);
        let searchResultList = await CompanyService.searchCompanies(
        {   page: 1,
            pageSize,
            searchTerm,
            sortBy: searchFilterParams.sortByParams,
            filterBy: searchFilterParams.filterByParams,
            sortDirection: searchFilterParams.sortDirection
        }
        );

        setIsCenterSpinnerSpinning(false)
        updateCompaniesList(searchResultList.data);
        setHasMoreDataToFetch(searchResultList.data?.length >= pageSize)
        setSearchTerm(searchTerm)
        setPage(1)
    };
    const loadMore = async () => {
        setIsBottomSpinnerSpinning(true);
        const searchPageNumber = page === 1 ? 2 : page;
        let searchResultList = await CompanyService.searchCompanies(
            {   page: searchPageNumber,
                pageSize,
                searchTerm,
                sortBy: searchFilterParams.sortByParams,
                filterBy: searchFilterParams.filterByParams,
                sortDirection: searchFilterParams.sortDirection
            }
        );
        updateCompaniesList([...searchResultListData, ...searchResultList.data]);
        setHasMoreDataToFetch(searchResultList.data?.length >= pageSize);
        setPage(searchPageNumber + 1);
        setIsBottomSpinnerSpinning(false);
    };

    const updateCompany = async (formData) => {
        await CompanyService.updateCompany(formData);
        setState({ ...state, isEditSelected: false });
        let companyToUpdate = searchResultListData.find((x) => x.id === formData.id);
        companyToUpdate.selected = true;
        Object.assign(companyToUpdate, formData);
        updateCompaniesList(searchResultListData);
        setSelectedCompany(formData);
    };

    const addNewCompany = async (formData) => {
        await CompanyService.addCompany(formData);
        setState({ ...state, isAddSelected: false });
    }

    const setSelectedIcon = (selectedIcon) => {
        setState({ [selectedIcon]: true });
    };


    const handleSubmit = async (formData) => {
        setSearchTerm("");

        if (state.isAddSelected) {
            await addNewCompany(formData);
        } else {
            await updateCompany(formData);
        }

        if (state.isAddSelected) {
            searchCompanies({
                page: 1, 
                pageSize: 10, 
                searchTerm: "",
                sortBy: searchFilterParams.sortByParams,
                filterBy: searchFilterParams.filterByParams,
                sortDirection: searchFilterParams.sortDirection
            });
            return;
        }
    };

    const editCompany = () => {
        setSelectedIcon("isEditSelected");
        setSelectedCompany(selectedCompany);
    }

    const addCompany = () => {
        setSelectedIcon("isAddSelected");
        setSelectedCompany({});
    };

    const deleteCompany = () =>{
        setSelectedIcon("isDeleteSelected")
    }

    const onDeleteCompanyConfirm = () => {
        const { removedRecordIndex, updatedCompaniesList, removedCompanyId } = removeSelectedCompanyFromList();

        setIsCenterSpinnerSpinning(true);
        onToggleDeleteCompanyConfirmationPopup(false);
        const searchPageNumber = page === 1 ? 2 : page;
        CompanyService.deleteCompany(removedCompanyId).then((response) => {
            setIsCenterSpinnerSpinning(false);
            searchCompanies(
                {
                    page: searchPageNumber,
                    pageSize,
                    searchTerm,
                    sortBy: searchFilterParams.sortByParams,
                    filterBy: searchFilterParams.filterByParams,
                    sortDirection: searchFilterParams.sortDirection
                },
                removedRecordIndex,
                updatedCompaniesList)
        }, (err) => {
            setIsCenterSpinnerSpinning(false);
        })
    }

    const onToggleDeleteCompanyConfirmationPopup = (isButtonClicked) => {
        setState({ ...state, isDeleteSelected: isButtonClicked });
      }

    const updateListRecordsIndex = (listData) => {
    let recordsIndex = {};
    listData.forEach((x, i) => {
        recordsIndex[x.id] = i;
    });
    setSearchedListRecordsIndex(recordsIndex);
    }

    const updateCompaniesList = (listData) => {
        setSearchResultListData(listData)
        updateListRecordsIndex(listData);
    }

    const removeSelectedCompanyFromList = () => {

        const removedCompanyId = selectedCompany.id;
        const filteredList = searchResultListData.filter(x => removedCompanyId !== x.id);
        let removedRecordIndex = searchedListRecordsIndex[removedCompanyId];
    
        if (filteredList?.length) {
            removedRecordIndex = filteredList[removedRecordIndex] ? removedRecordIndex : removedRecordIndex - 1;
            filteredList[removedRecordIndex].selected = true;
            setSelectedCompany(filteredList[removedRecordIndex]);
        }
    
        updateCompaniesList(filteredList)
    
        return { removedRecordIndex, updatedCompaniesList: filteredList, removedCompanyId };
      }

    return (
        <section className="content-box">
            <div className="search-section pt-33">

                <div className='search-box-container'>

                <SearchBox
                    placeholder="Search for companies"
                    onSearch={searchData}
                    shouldUseFilter={true}
                    searchFilterParams={searchFilterParams}
                    onSearchFilterChange={(updatedFilterParams) => onSearchFilterChange(updatedFilterParams)}

                />

                </div>

                <div className='search-list-container'>

                    <div className='search-result-container shadow'>
                        <FlatList
                            hasMoreDataToFetch={hasMoreDataToFetch}
                            loadMore={loadMore}
                            data={searchResultListData}
                            leftContentKey="name"
                            rightContentKey="createdDate"
                            onRowClick={resultListClick}
                            isFetchingMoreData={isBottomSpinnerSpinning}
                            isSearchingData={isCenterSpinnerSpinning}
                        />
                    </div>
                </div>

            </div>


            <div className="details-section pt-33">
                <div className='action-buttons-section'>

                <div className='left-action-buttons-group'>
                        <div className='mr-5 d-flex '>
                            <IconButton
                                tooltipTitle=""
                                tooltipPostion="top"
                                isActive={state.isAddSelected}
                                onClick={() => addCompany()}
                                name="add"
                                as="button"
                                label="Create company"
                            />
                        </div>
                    </div>

                    <ButtonGroup className="action-buttons-group">

                        <div className="mr-5">
                            <IconButton
                                tooltipTitle="Chat"
                                tooltipPostion="top"
                                isActive={state.isChatSelected}
                                onClick={() => setSelectedIcon('isChatSelected')}
                                name="chat"
                                as="button"
                            />
                        </div>

                        <div className="mr-5">
                            <IconButton
                                tooltipTitle="Edit"
                                tooltipPostion="top"
                                isActive={state.isEditSelected}
                                onClick={() => editCompany()}
                                name="edit"
                                as="button"
                                disabled={state.isAddSelected}
                            />
                        </div>
                        <div className="mr-5">
                            <IconButton
                                disabled={!selectedCompany?.id || state.isEditSelected}
                                tooltipTitle="Delete"
                                tooltipPostion="top"
                                isActive={state.isDeleteSelected}
                                onClick={() => deleteCompany()}
                                name="delete"
                                as="button"
                            />
                        </div>
                        <div className="mr-5">

                            <IconButton
                                tooltipTitle="Bookmark"
                                tooltipPostion="top"
                                isActive={state.isBookmarkSelected}
                                onClick={() => setSelectedIcon('isBookmarkSelected')}
                                name="bookmark"
                                as="button"
                            />

                        </div>

                    </ButtonGroup>

                </div>

                <Spinner
                    IsCenterSpinnerSpinning={
                        isCenterSpinnerSpinning
                    }
                />


<div className="details-section-scroll-under-action-buttons">
                <div className='details-section-container '>
                    <div className='details-container shadow'>

                        <div className="row pl-25 pr-25">
                            <div className="col-12 col-xl-12 col-xxl-12">

                                <RenderIf
                                    shouldRender={!state.isEditSelected && !state.isAddSelected && selectedCompany}
                                >
                                    <CompanyInfo
                                        selectedCompany={selectedCompany}
                                    />
                                </RenderIf>

                                <RenderIf
                                    shouldRender={state.isEditSelected || state.isAddSelected && selectedCompany}
                                >
                                    <CompanyForm
                                        selectedCompany={selectedCompany}
                                        state={state}
                                        handleSubmit={handleSubmit}
                                    />
                                </RenderIf>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <ConfimrationDialog
                isModalOpen={state.isDeleteSelected}
                onToggleDialog={(isDialogOpen) => onToggleDeleteCompanyConfirmationPopup(isDialogOpen)}
                confimrationMessage={`Do you want to delete ${selectedCompany.name}`}
                okBtnText={'Delete'}
                cancelBtnText={'Cancel'}
                title="Delete Company"

                cancelBtnStyleType="white"
                cancelBtnClick={() => onToggleDeleteCompanyConfirmationPopup(false)}
                okBtnClick={() => onDeleteCompanyConfirm()}
            />
        </section>
    );
};

export default Companies;

const FormField = ({ label, value }) => (
    <div className="form-field">
        <label htmlFor="field">{label}</label>
        {value}
    </div>
);

const CompanyInfo = ({ selectedCompany }) => (
    <div className="form-inline">
        <FormField label="Name:" value={selectedCompany.name} />
        <FormField label="Created Date:" value={selectedCompany.dateCreated} />
        <FormField label="Created By:" value={selectedCompany.createdBy} />

    </div>
);

const CompanyForm = ({ selectedCompany, state, handleSubmit }) => {
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required.")
    });

    return (
        <Formik
            initialValues={{
                ...selectedCompany,
                name: selectedCompany.name || "",
                twoFactorEnabled: selectedCompany.twoFactorEnabled || false,
            }}
            enableReinitialize={true}
            validationSchema={!state.isEditSelected && validationSchema}
            onSubmit={(values) => {
                //console.log("form data ", values);
                handleSubmit(values);
            }}
        >
            <Form>
                <div className="form-inline">

                    <Field component={TextInput} name="name" label="Name:" />

                    {/* <Field
            name="twoFactorEnabled"
            label="Two Factor Enabled:"
            position="left"
            component={Checkbox}
          /> */}
                </div>
                <div className="content-align-right mt-30">
                    <Button text={state.isEditSelected ? "Update" : "Add"} />
                </div>
            </Form>
        </Formik>
    );
};
