import React, { useEffect, useState } from "react";
import { Field, Formik, Form } from 'formik';
import { BsSortUp, BsSortDown } from 'react-icons/bs'

import { RenderIf } from "..";
import { Checkbox } from "..";
import { Switch } from "..";
import { Button } from "..";

const SearchFilter = ({ sortByParams, filterByParams, onToggleSearchFilter, onChange, sortByDirection }) => {

    const sortDirections = {
        asc:'Asc',
        desc: 'Desc'
    }
    const [filterFormState, setFilterFormState] = useState({});
    const [searchParams, setSearchParams] = useState({
        sortBy: [],
        filterBy: [],
        sortDirection: sortByDirection
    });
    const sortByPropertyPrefix = "sortBy_";
    const filterByPropertyPrefix = "filterBy_";
    
    
    useEffect(() =>{
        setSearchFilterInitialState(sortByParams, filterByParams);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const setSearchFilterInitialState = (sortByParams, filterByParams) => {

        let sortBy = [];
        let filterBy = [];
        let formState = {};

        sortByParams.forEach(x => {
            const keyName = `${sortByPropertyPrefix}${x.key}`;
            formState[keyName] = x?.value;
            sortBy.push({formKeyName: keyName, ...x})
        });

        filterByParams.forEach(x => {
            const keyName = `${filterByPropertyPrefix}${x.key}`;
            formState[keyName] = x?.value;
            filterBy.push({formKeyName: keyName, ...x})
        });

        setSearchParams({sortBy, filterBy, sortDirection:sortByDirection});
        setFilterFormState({...formState});
    }

    const onApplyBtnClick = (updatedFilterValues) => {
        
        const sortBy =  searchParams.sortBy.map(data => {
            const {formKeyName, ...restParams} = data;
            return {...restParams, value: updatedFilterValues[formKeyName]}
        })

        const filterBy =  searchParams.filterBy.map(data => {
            const {formKeyName, ...restParams} = data;
            return {...restParams, value: updatedFilterValues[formKeyName]}
        })

        const sortByParams = sortBy.find(param => param.value)?sortBy.find(param => param.value).key:"";
        const filterByParams =  filterBy.filter(param => param.value).map(x => x.key).join();

        onChange({sortBy, filterBy, sortByParams, filterByParams, sortDirection: searchParams.sortDirection});
        onToggleSearchFilter();
    }

    const onCancelBtnClick = () => {
        onToggleSearchFilter();
    }

    const onSortItemSelection = (selectedSortItemKeyName, formValues, setFormValues) => {
        
        const formState = {...formValues};
        const sortByFormKeyNames = searchParams.sortBy.map(x => x.formKeyName);

        sortByFormKeyNames.forEach(keyName => {
            formState[keyName] = selectedSortItemKeyName === keyName
        })
        setFormValues(formState);
        setSearchParams({...searchParams, sortDirection: sortDirections.desc});
    }

    const onSortDirectionChange = (sortByDirection) => {
        setSearchParams({...searchParams, sortDirection: sortByDirection});
    }

    return <Formik
        initialValues={{
         ...filterFormState    
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
           onApplyBtnClick(values);
        }}
    >
        {({ values, setValues }) => (
            <Form >
                <div className="search-filter-popup-container">
                    <h6 className="search-filter-sortby-title">SORT BY:</h6>
                    <RenderIf shouldRender={sortByParams && sortByParams.length}>
                        {searchParams.sortBy.map((param, index) =>
                            <div className="search-filter-sortBy-item" key={index}>
                            <Field
                            
                            name={param.formKeyName}
                            label={param.displayName}
                            onClick={() => onSortItemSelection(param.formKeyName, values, setValues)}
                            component={Checkbox}
                          />
                          <RenderIf shouldRender={values[param.formKeyName]}>
                          <SortByDirectionButton  sortDirection={searchParams.sortDirection} onDirectionChange={onSortDirectionChange} />
                          </RenderIf>
                          </div>)}
                    </RenderIf>

                    

                    <hr className="divider-line mt-20"></hr>

                    <RenderIf shouldRender={filterByParams && filterByParams.length}>
                        <div className="search-filter-bottom-section">
                        {searchParams.filterBy.map((param, index) =>
                        <div className="mt-10" key={index}>
                            <Field
                            name={param.formKeyName}
                            label={param.displayName}
                            position="left"
                            component={Switch}
                          />
                          </div>)}
                          </div>
                    </RenderIf>

                    <div className="content-align-right mt-30">
                            <Button type="submit" text={'Apply'}  />
                            <span className="ml-10"><Button type="button" text="Cancel" styleType="secondary" onClick={() => onCancelBtnClick()} /></span>
                        </div>
                </div>
            </Form>)}
    </Formik>
}

const SortByDirectionButton = ({ sortDirection, onDirectionChange}) => {

    const sortDirections = {
        asc:'Asc',
        desc: 'Desc'
    }

    const [currentDirection, setCurrentDirection] = useState(sortDirection);

    useEffect(() => {
        setCurrentDirection(sortDirection);
        console.log(sortDirection);
    },[sortDirection])

    const onToggleDirection = (direction) => {
        setCurrentDirection(direction);
        onDirectionChange(direction);
    }
    
    return <>
         
         <RenderIf shouldRender={currentDirection === sortDirections.asc} >
             <BsSortUp className="cursor-pointer" color="#72a2e2" size={20} onClick={() => onToggleDirection(sortDirections.desc)} />
             </RenderIf>
         
         <RenderIf shouldRender={currentDirection === sortDirections.desc}>
             <BsSortDown className="cursor-pointer" color="#72a2e2" size={20} onClick={() => onToggleDirection(sortDirections.asc)} />
             </RenderIf>
         </>

}

export default SearchFilter;