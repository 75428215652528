import React, { Component } from "react";
import { Iconly } from "react-iconly";
import RenderIf from "../render-if/RenderIf";

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            pathName: ""
        };
        this._isComponentMounted = false;
    }

    componentDidMount() {
        const { History, Routes, InitialRoutes, RoutePrefixes } = this.props
        this._isComponentMounted = true;
        this._isComponentMounted && this.setState({ routes: InitialRoutes });


        History.listen(({ pathname }) => {
            let allowedRoutes = null
            if (RoutePrefixes && RoutePrefixes?.length) {
                allowedRoutes = pathname
                    .split('/').filter(x => x).slice(2);
                // .filter((x) => x && !parseInt(x) && !RoutePrefixes.includes(x))
            } else {
                allowedRoutes = pathname.split('/').filter((x) => x)
            }

            const routes = allowedRoutes.map((route) => {
                let selectedRoute = Routes.find(
                    (homeRoute) => homeRoute.path === route,
                )
                if (selectedRoute) {
                    return { routeLocation: selectedRoute.location, routeDisplayName: selectedRoute.displayName }
                }
                return undefined;
            }).filter(x => x);
            this._isComponentMounted && this.setState({ routes, pathName: pathname })
        })
    }

    componentDidUpdate() {
        const { History } = this.props;
        
        if (History.location.pathname !== this.state.pathName) {
            
            History.push(History.location.pathname);
        }
    }

    componentWillUnmount() {
        this._isComponentMounted = false
    }

    onRouteClick = (routeLocation) => {
        const { History, RoutePrefixes, Routes } = this.props;
        if (RoutePrefixes && RoutePrefixes.length) {
            const selectedRoutes = routeLocation.split("/").filter(x => x);
            const pathNames = this.state.pathName.split("/").filter(x => {
                const doesPathExists = Routes.find(y => y.path === x);
                if (doesPathExists) {
                    return true;
                }
                return false;
            });

            const isLastRouteNameClicked = selectedRoutes[selectedRoutes.length - 1] === pathNames[pathNames.length - 1];
            if (isLastRouteNameClicked) {
                return;
            }

            let firstRouteIndexToIgnore = null;
            let urlPathToNavigate = this.state.pathName.split("/").filter((x, index) => {

                if (firstRouteIndexToIgnore || !x) {
                    return false;
                }

                const shouldThisRouteNameBeIgnored = selectedRoutes.find(y => y === x);

                if ((x && shouldThisRouteNameBeIgnored) || (x && firstRouteIndexToIgnore && index >= firstRouteIndexToIgnore)) {
                    firstRouteIndexToIgnore = index;
                    return false
                }
                return true;

            });
            urlPathToNavigate = urlPathToNavigate.join("/") + routeLocation;
            History.push("/" + urlPathToNavigate);
            return;
        }
        History.push(routeLocation);
    };

    render() {
        const { routes } = this.state;
        const { AdditionalData } = this.props;

        return (
            <React.Fragment>
                {routes.length &&
                    routes.map((route, index) => {
                        const isLastIndex = routes.length - 1 === index;
                        return (
                            <span

                                className={"breadcrumb-route-name" +  (isLastIndex ? ' breadcrumb-active' : '')}
                                key={`route-${index}`}
                                onClick={() => this.onRouteClick(route.routeLocation)}
                            >
                                {route.routeDisplayName}

                                {!isLastIndex && (
                                    <Iconly
                                        name="ChevronRight"
                                        set="light"
                                        primaryColor="#979CA2"
                                        size="small"
                                        className="ml-5 mr-5"
                                    ></Iconly>
                                )}
                            </span>
                        );
                    })}

                    <RenderIf shouldRender={AdditionalData} >

                                <span

                                className="breadcrumb-route-name breadcrumb-active"
                                
                                onClick={() => ""}
                            >
                                <Iconly
                                        name="ChevronRight"
                                        set="light"
                                        primaryColor="#979CA2"
                                        size="small"
                                        className="ml-5 mr-5"
                                    ></Iconly>
                                {AdditionalData}
                            </span>

                        </RenderIf>
            </React.Fragment>
        );
    }
}

export { Breadcrumb };
