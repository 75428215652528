
export default class StorageService {

    static Set(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static Get(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    static Remove(key) {
        localStorage.removeItem(key);
    }
}