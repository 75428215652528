const FrmscApp = {
  Admin: 'admin',
}

const NavigationRoutes = [
  { title: 'Dashboard', path: '/dashboard', iconName: 'dashboard' },
  { title: 'Companies', path: '/companies', iconName: 'companies' },
  { title: 'Instances', path: '/instances', iconName: 'instances' },
  { title: 'Users', path: '/users', iconName: 'users' },
  { title: 'Connected', path: '/connected', iconName: 'connected' },
  { title: 'Packages', path: '/packages', iconName: 'packages' },
  { title: 'Clients', path: '/clients', iconName: 'clients' },
  { title: 'Api Scopes', path: '/api-scopes', iconName: 'api' },
]

const BreadcrumbRoutes = [
    { path: 'connected', displayName: "Connected", location:'/connected' },
    { path: 'dashboard', displayName: "Dashboard", location:'/dashboard'  },
    { path: 'companies', displayName: "Companies", location:'/companies'  },
    { path: 'instances', displayName: "Instances", location:'/instances'  },
    { path: 'connected', displayName: "Connected", location:'/connected'  },
    { path: 'packages', displayName: "Packages", location:'/packages'  },
    { path: 'users', displayName: "Users", location:'/users'  },
    { path: 'clients', displayName: "Clients", location:'/clients'  },
    { path: 'api-scopes', displayName: 'Api Scopes', location:'/api-scopes' },
]


const RedirectPaths = {
  UnAuthorizedAccessFallbackPage: 'un-authorized',
  LoginRedirectionCallbackPage: 'login-redirect',
  Dashboard: 'dashboard'
}

const RouteConstants = {
  NavigationRoutes,
  BreadcrumbRoutes,
  FrmscApp,
  RedirectPaths
}

export default RouteConstants
