
const AppConfig = {

    environment: process.env.REACT_APP_ENV,
    frmscAdminApiUrl: process.env.REACT_APP_API_URL,
    hostUrl: window.location.origin,
    frmscIdentityUrl: process.env.REACT_APP_IDENTITY_AUTHORITY_URL    


};

export default AppConfig;