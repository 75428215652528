import React from "react";

const Connected = () => {
 

  return (
 
          <div>Connected Page</div>
      
  );
};

export default Connected;
