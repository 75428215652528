import React from 'react'

const SwitchLabelPosition = {
  Right: 'right',
  Left: 'left',
}

const Switch = ({ label, position, xAxisAlignment,...props }) => {
  return (
    <label className="toggle" style={{justifyContent: xAxisAlignment}}>
      {label && position === SwitchLabelPosition.Left && (
        <span className="toggle-label">{label}</span>
      )}

      <SwitchControl {...props} />

      <div className="toggle-switch"></div>
      {label && position === SwitchLabelPosition.Right && (
        <span className="toggle-label">{label}</span>
      )}
    </label>
  )
}

Switch.defaultProps = {
  position: 'left',
  xAxisAlignment: 'center'
}

export default Switch

const SwitchControl = ({ field, ...restProps }) => {
  return (
    <>
      <input
        {...field}
        checked={field.value}
        className="toggle-checkbox"
        type="checkbox"
      />
    </>
  )
}
