import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from "./routes";

import "./app/assets/_global.scss";

import UserManagerService from "./app/services/UserManagerService";


function App() {
  useEffect(() => {

    return () => {
      UserManagerService.events.removeAccessTokenExpired();
    };
  }, []);

  return (
    <div className="container">
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
