import axios from "axios";
import { Url } from "../shared/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

export default class CompaniesService {
  static searchCompanies = async (searchSortPayload) => {
    return await axios
      .post(
        `${baseApiUrl}${Url.company.search}`,searchSortPayload
      )
      .then((response) => response);
  };

  static getAllCompanies = async () => {
    return await axios
      .get(
        `${baseApiUrl}${Url.company.getAllCompanies}`
      )
      .then((response) => response);
  };

  static updateCompany(companyInfo) {
    return axios
      .put(`${baseApiUrl}${Url.company.updateCompany}`, companyInfo)
      .then((response) => response);
  }

  static addCompany(companyInfo) {
    return axios
      .post(`${baseApiUrl}${Url.company.addCompany}`, companyInfo)
      .then((response) => response);
  }

  static deleteCompany(companyId) {
    return axios
      .get(`${baseApiUrl}${Url.company.deleteCompany}/${companyId}`)
      .then((response) => response);
  }
}
