import { Component } from "react";

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {getRef} = this.props;
    
    if(getRef)
    {
    const eleRef = this.props.getRef();
    eleRef.current.addEventListener("scroll", () => {
      if (
        eleRef.current.scrollTop + eleRef.current.clientHeight >=
        eleRef.current.scrollHeight
      ) {
        this.props.HasMoreDataToFetch && this.props.loadMore();
      }
    });
     }
  }

  render() {
    return this.props.children
  }
}

export { InfiniteScroll };