import React from 'react';
import SvgIcon from '../svg-icons/SvgIcon';
import RenderIf from '../render-if/RenderIf';

const GenericCard = ({ isActive, data, cardDetails, leftContentKey, rightContentKey, onClick, rostersIndex, onLinkClick }) => {

  return (
    <div className={`roaster-card ${isActive ? ' active' : ''}`} onClick={() => onClick(data, rostersIndex)}>
      <div className="row pl-10 pr-10">
        <div className="col">
          <div className="roaster-name">{data[leftContentKey]} <span className="ml-5"></span></div>
        </div>
        <div className="col text-right">
          <div className="roaster-date">{data[rightContentKey]}</div>
        </div>
      </div>
      <div className="row pl-10 pr-10">
        {cardDetails &&
          cardDetails.length &&
          cardDetails.map((x, index) => {

            return (
              <div key={index} className="col-4 mt-10">
                <div className="roaster-info">
                  <div className="icon">
                  <SvgIcon name={x.icon} />
                  </div>
                  <div className="roaster-text">
                    {x.label && <div className="roaster-heading">{x.label}</div>}
                    <RenderIf shouldRender={!x.shouldRenderAsLink}><div className="roaster-detail">{data[x.key]}</div></RenderIf>
                    <RenderIf shouldRender={x.shouldRenderAsLink}><span  className="roaster-detail hyperlink-text" onClick={(e) => {
                      e.stopPropagation();
                      onLinkClick(data)}}>{data[x.key]}</span></RenderIf>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default GenericCard
