import React, { Component } from 'react';

import './ToolTip.css';
const DEFAULTBACKGROUNDCOLOR = "#7385AB";
class ToolTip extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children, title, position, backgroundColor } = this.props
        const BackgroundColor = backgroundColor ? backgroundColor : DEFAULTBACKGROUNDCOLOR
        return (
            <>
                {(position === 'right' || position === 'left') && <div className="tooltip" style={{ display: 'flex', position: 'relative' }}>

                    {position === 'right' && <><div >{children}</div>
                        <div className={"tooltiptext right"} style={{ backgroundColor: BackgroundColor }}>{title}</div>
                    </>}

                    {position === 'left' && <><div className={"tooltiptext left"} style={{ backgroundColor: BackgroundColor }}>{title}</div>
                        <div >{children}</div></>}
                </div>}

                {(position === 'top' || position === 'bottom') && <div className="tooltip" style={{ position: 'relative', alignItems: 'center' }}>

                    {position === 'top' && <>
                        <div className={"tooltiptext top"} style={{ backgroundColor: BackgroundColor }}>{title}</div>
                        <div style={{ height: '100%', width: '100%' }}>{children}</div>
                    </>}

                    {position === 'bottom' && <>
                        <div >{children}</div>
                        <div className={"tooltiptext bottom"}>{title}</div>
                    </>}

                </div>}


            </>
        );
    }
}

export default ToolTip;