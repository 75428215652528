import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router-dom';

import axios from "axios";

import UserManagerService from "../../../services/UserManagerService";

import "../styles/login.css";

const baseUrl = process.env.REACT_APP_API_URL;

const Signin = () => {
  // const history = useHistory();

  // const handleSubmit = () => {
  //  history.push('/clients');
  // };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [profileClaims, setProfileClaims] = useState("");

  useEffect(() => {
    UserManagerService.getUser().then((user) => {
      if (user) {
        // const {profile} = user;
        setIsAuthenticated(true);
        // setProfileClaims(profile);
      }
    });
  }, []);

  const signiIn = async () => {
    UserManagerService.signinRedirect();
  };

  const signiOut = () => {
    UserManagerService.revokeAccessToken().then(resp => {
      UserManagerService.signoutRedirect();
    })
  };

  const callCompanyApi = () => {
    axios
      .get("https://${baseUrl}/api/Company", { withCredentials: true })
      .then((Resp) => {});
  };

  return (
    <React.Fragment>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="row">
        {/* <div className='col-sm-12'>
            <button type='submit' className='btn btn-primary btn-block login-btn'>Log in
                              </button>
          </div> */}

        <div className="col-12 mt-2">
          {!isAuthenticated && (
            <button
              className="btn btn-md btn-primary mt-2"
              onClick={() => signiIn()}
            >
              SignIn
            </button>
          )}
          {isAuthenticated && (
            <button
              className="btn btn-md btn-primary mt-2"
              onClick={() => signiOut()}
            >
              Sign Out
            </button>
          )}
          {isAuthenticated && (
            <button
              className="btn btn-md btn-primary mt-2"
              onClick={() => callCompanyApi()}
            >
              Company Api
            </button>
          )}
        </div>
      </div>
      {/* </form> */}
    </React.Fragment>
  );
};

export default Signin;
