import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { Breadcrumb } from "../breadcrumb/Breadcrumb";

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      UserName,
      ProfileLinkText,
      ProfilePictureImg,
      InitialRoutes,
      Routes,
      History,
      RoutePrefixes,
      ProfilePagePath,
      BreadcrumbData
    } = this.props;

    return (
      <header>
        <div className="theme-header">
          <div className="header-left">
            <span className="header-title d-flex">
              <Breadcrumb
                History={History}
                Routes={Routes}
                InitialRoutes={InitialRoutes}
                RoutePrefixes={RoutePrefixes}
                AdditionalData={BreadcrumbData}
              />
            </span>
          </div>
          <div className="header-right">
            <div className="user-profile">
              <div className="user-short-info">
                <span>{UserName}</span>
                <NavLink to={ProfilePagePath}>
                  {ProfileLinkText}
                </NavLink>
              </div>
              <img
                src={ProfilePictureImg}
                alt="user-img"
                className="user-avatar-small"
              />

              <ThreeDotsIcon />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export { PageHeader };

const ThreeDotsIcon = () => (
  <button className="button-toggle-icon" type="button">
    <svg
      width="5"
      height="19"
      viewBox="0 0 5 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" />
      <circle cx="2.5" cy="16.5" r="2.5" />
      <circle cx="2.5" cy="9.5" r="2.5" />
    </svg>
  </button>
);

// import React, { Component } from "react";
// import { Iconly } from "react-iconly";
// import {Breadcrumb} from '../breadcrumb/Breadcrumb'
// import "./PageHeader.css";

// className PageHeader extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     const {
//       Title,
//       UserName,
//       ProfileLinkText,
//       ProfilePictureImg,
//       BackgroundColor,
//       InitialRoutes,
//       Routes,
//       History,
//       RoutePrefixes,
//       OnProfileLinkTextClick
//     } = this.props;
//     return (
//       <div
//         className="pageHeaderContainer"
//         style={{ backgroundColor: BackgroundColor ? BackgroundColor : "white" }}
//       >
//         <div className="pageHeaderLeftSection">
//           <Breadcrumb History={History} Routes={Routes} InitialRoutes={InitialRoutes} RoutePrefixes={RoutePrefixes}  />
//           </div>
//         <div className="pageHeaderRightSection">
//           <div className="pageHeaderUserNameContainer">
//             <div className="pageHeaderUserName">{UserName}</div>
//             <a href="#" className="pageHeaderViewProfileText" onClick={OnProfileLinkTextClick}>
//               {ProfileLinkText}
//             </a>
//           </div>

//           <div className="pageHeaderProfilePictureContainer">
//             {ProfilePictureImg && (
//               <img
//                 src={ProfilePictureImg}
//                 className="pageHeaderProfilePicture"
//               ></img>
//             )}
//             {!ProfilePictureImg && (
//               <Iconly name="User" set="light" primaryColor="white" />
//             )}
//           </div>

//           <div className="pageHeaderPopupIconContainer">
//             <Iconly
//               name={"MoreSquare"}
//               set="light"
//               primaryColor="rgb(114, 162, 226)"
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export { PageHeader };
