import {useEffect} from 'react';
import UserManagerService from '../../../services/UserManagerService';

const SignOutCallback = (props) => {

    useEffect(() => {
      UserManagerService.signinRedirect();
    },[])

    return(
        <></>
    )


}

export default SignOutCallback;