import React, { useEffect, useState } from "react";
import RenderIf from "../render-if/RenderIf";

const Pagination = ({ totalRecordsCount, buttonsCount, pageSize, onPageChange, searchedKeyword, pageNumber }) => {

    const [currentButtonGroupIndex, setCurrentButtonGroupIndex] = useState(1);
    const [currentGroupLastButton, setCurrentGroupLastButton] = useState(0);
    const [havePreviousButtons, setHavePreviousButtons] = useState(false);
    const [haveNextButtons, setHaveNextButtons] = useState(false);
    const [pageNumberButtons, setPageNumberButtons] = useState([]);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [recordsCount, setRecordsCount] = useState(0);
    const [keyword, setKeyword] = useState(false);

    useEffect(() => {
        setCurrentButtonGroupIndex(1);
        setSelectedPageNumber(1)
        updatePagingButtons(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalRecordsCount, buttonsCount, pageSize, searchedKeyword])

    useEffect(() => {
        if(pageNumber && pageNumber !== selectedPageNumber)
        {
         selectButton(pageNumber)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const updatePagingButtons = (showNextButtonGroup) => {
       
        if(totalRecordsCount && pageSize)
        {
            let totalButtonsCount = totalRecordsCount / pageSize;
            totalButtonsCount = Number.isInteger(totalButtonsCount) ? totalButtonsCount : parseInt(totalButtonsCount) + 1;

            const currentGroupLastButtonNumber = checkIfRecordsHaveChanged()? 0 : currentGroupLastButton;
            const remaingButtonsCount = totalButtonsCount - currentGroupLastButtonNumber;
            let currentGroupButtonsCount = remaingButtonsCount >= buttonsCount || !showNextButtonGroup ? buttonsCount : remaingButtonsCount;

            let buttons = showNextButtonGroup ? Array.from({ length: currentGroupButtonsCount }, (_, buttonIndex) => currentGroupLastButtonNumber + (buttonIndex + 1)) : Array.from({ length: currentGroupButtonsCount }, (_, buttonIndex) => (pageNumberButtons[0] + buttonIndex) - buttonsCount);
            
            setRecordsCount(totalRecordsCount);
            setKeyword(searchedKeyword);
            setHavePreviousButtons(buttons[0] !== 1);
            setHaveNextButtons(remaingButtonsCount > buttonsCount || !showNextButtonGroup);
            setCurrentGroupLastButton(buttons[buttons.length - 1]);
            setPageNumberButtons(buttons);
       }
    }

    const swapButtonGroup = (buttonGroupIndex, fetchNextGroup, buttonIndex) => {
        selectButton(buttonIndex);
        setCurrentButtonGroupIndex(buttonGroupIndex);
        updatePagingButtons(fetchNextGroup);
    };

    const selectButton = (pageNumber) => {
        setSelectedPageNumber(pageNumber);
        onPageChange(pageNumber, keyword);
    }

    const selectNextButton = () => {

        const nextButtonNumber = selectedPageNumber + 1;
        const lastPageNumberButton = getLastPageNumber();
        
        if ( lastPageNumberButton >= nextButtonNumber) {
            setSelectedPageNumber(nextButtonNumber);
            onPageChange(nextButtonNumber, keyword);
        }
        else {
            if (haveNextButtons) {
                showNextButtonGroup();
            }
        }
    }

    const selectPreviousButton = () => {

        const previousButtonNumber = selectedPageNumber - 1;
        const firstPageNumberButton = getFirstPageNumber();

        if ( firstPageNumberButton <= previousButtonNumber && previousButtonNumber > 0) {
            setSelectedPageNumber(previousButtonNumber);
            onPageChange(previousButtonNumber, keyword);
        }
        else {
            if (havePreviousButtons) {
                showPreviousButtonGroup();
            }
        }
    }

    const showNextButtonGroup = () => {
        const lastPageNumberButton = getLastPageNumber();
        swapButtonGroup(currentButtonGroupIndex + 1, true, lastPageNumberButton + 1);
    }

    const showPreviousButtonGroup = () => {
        const firstPageNumberButton = getFirstPageNumber();
        swapButtonGroup(currentButtonGroupIndex - 1, false, firstPageNumberButton - 1);
    }

    const getFirstPageNumber = () => {
        return pageNumberButtons[0];
    }

    const getLastPageNumber = () => {
        return pageNumberButtons[pageNumberButtons.length - 1];
    }

    const checkIfRecordsHaveChanged = () => {
        return (recordsCount !== totalRecordsCount) || (keyword !== searchedKeyword );
    }

    return <RenderIf shouldRender={totalRecordsCount && pageSize}><div className="col-12 mt-10">
        <div className="inline-buttons-group">

            <div className="pagination-btn-group-changer-btn mr-10" onClick={selectPreviousButton}>Previous</div>

            <PagerButton shouldRender={havePreviousButtons} onClick={showPreviousButtonGroup}></PagerButton>

            <RenderIf shouldRender={pageNumberButtons && pageNumberButtons.length}>
                {pageNumberButtons.map((button, buttonIndex) => <div key={buttonIndex} className={`pagination-btn ${button === selectedPageNumber ? ' pagination-active-btn' : ''}`} onClick={() => selectButton(button)}>{button}</div>)}
            </RenderIf>

            <PagerButton shouldRender={haveNextButtons} onClick={showNextButtonGroup}></PagerButton>
            
            <div className="pagination-btn-group-changer-btn ml-10" onClick={() => selectNextButton()}>Next</div>
        </div>
    </div>
    </RenderIf> 
}

const PagerButton = ({shouldRender, onClick}) => {
    return <RenderIf shouldRender={shouldRender}>
                <div className="pagination-btn" onClick={onClick}>...</div>
    </RenderIf>
}

export default Pagination;