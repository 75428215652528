import React, { Component } from "react";

class Spinner extends Component {

  render() {
    const { IsCenterSpinnerSpinning ,IsBottomSpinnerSpinning, IsRightSpinnerSpinning, SpinnerText } = this.props;
    return (
      <>
        {IsCenterSpinnerSpinning && <div className="centerSpinnerWrapper">
        <div className="centerSpinner"></div>
        <h5 className="spinnerText mt-10">{SpinnerText}</h5>
          </div>}
        {IsRightSpinnerSpinning && <div className="rightSpinnerWrapper">
        <div className="rightSpinner"></div>
        <h5 className="spinnerText mt-10">{SpinnerText}</h5>
          </div>}
        { IsBottomSpinnerSpinning && <div className="bottomSpinnerWrapper">
          <div className="bottomSpinner"></div>
          <h5 className="spinnerText mt-10">{SpinnerText}</h5>
        </div>}
      </>


    );
  }
}

export { Spinner } ;
